<script lang="ts">

    import {
        Button,
        Modal,
        ModalBody,
        ModalFooter,
        ModalHeader,
    } from '@sveltestrap/sveltestrap';

    let onOk = null;
    let onCancel = null;
    let open = false;

    export async function showDialog() {
        return new Promise((resolve, reject) => {
            open = true;
            onOk = () => {
                open = false;
                resolve(true);
            };
            onCancel = () => {                
                open = false;
                resolve(false);
            };
        });
    }

</script>

<div>
    <Modal isOpen={open}>
        <ModalHeader>Wirklich löschen</ModalHeader>
        <ModalBody>
            Soll diese Bestellreferenz wirklich gelöscht werden?
        </ModalBody>
        <ModalFooter>
            <Button color="primary" on:click={onOk}>Löschen</Button>
            <Button color="secondary" on:click={onCancel}>Abbrechen</Button>
        </ModalFooter>
    </Modal>
</div>
