<div>
    <Modal size="xl" isOpen={open}>
        <div class="modal-header">
            <h5 class="modal-title">
                {#if selectedUploadOption}
                    {selectedUploadOption.text}
                {:else}
                    Task anlegen
                {/if}
            </h5>
            {#if selectedUploadOption?.description} 
                <h6 class="modal-subtitle">
                    {selectedUploadOption.description}
                </h6>
            {/if}
        </div>
        <ModalBody style="margin-left: -.75rem; margin-right: -.75rem;">
            {#if !selectedUploadOption}
                <Container>
                    <Row>
                        <p>
                            Bitte wählen Sie zunächst den auszuführenden Task.
                        </p>
                    </Row>
                    <Row>
                        <FormGroup>
                            <Label for="mappenTyp">Typ</Label>
                            <Input type="select" name="select" id="mappenTyp" 
                                bind:value={selectedUploadOption}
                                valid={selectedUploadOption != null}>
                                {#each TASK_TYPES as o}
                                    <option value={o}>{o.text}</option>
                                {/each}
                            </Input>
                        </FormGroup> 
                    </Row>
                </Container>      
            {:else}          
                <svelte:component 
                    this={selectedUploadOption?.inputComponent} 
                    bind:inputDocument={inputDocument}
                    bind:inputIsValid={inputIsValid} />
            {/if}         
        </ModalBody>
        <ModalFooter>
            {#if selectedUploadOption}
            <Button color="light" on:click={dialogBack}>
                Zurück
            </Button>
            <Button color="primary" disabled={!inputIsValid} on:click={onOk}>
                Task anlegen
            </Button>
            {/if}
            <Button color="secondary" on:click={onCancel}>
                Abbrechen
            </Button>
        </ModalFooter>
    </Modal>
</div>

<style lang="scss">
    .modal-header {
        display: grid;
    }
    .modal-subtitle {
        margin-bottom: 0;
        margin-top: 0.5rem;
    }
</style>

<script>

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';
  
    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';

    import {
        Button,
        Modal,
        ModalBody,
        ModalFooter,
        ModalHeader,
    } from '@sveltestrap/sveltestrap';

    import { triggerTask } from "@/clients/apdbClient";
    import { TASK_TYPES } from "./TaskTypes.js";

    let selectedUploadOption = null;

    let open = false;

    let inputDocument;
    let inputIsValid;

    let onOk = null;
    let onCancel = null;

    function dialogBack() {
        selectedUploadOption = null;
    }

    export async function showDialog() {
        
        return new Promise((resolve, reject) => {
            selectedUploadOption = null;
            open = true;
            onOk = () => {
                triggerTask(selectedUploadOption.triggerPath, inputDocument)
                    .finally(() => {
                        open = false;
                        resolve();
                    })
            };
            onCancel = () => {                
                open = false;
                resolve();
            };
        });
    }

</script>

