<div>
    <Modal isOpen={open}>
        <div class="modal-header">
            <h5 class="modal-title">
                FBA_SELECTION
            </h5>
        </div>
        <ModalBody>
            <Row>
                <FormGroup>
                    <a href="{`samples/FBASelectionExcludeList.xlsx`}">
                        Beispieldatei
                    </a>
                </FormGroup>  
                <FormGroup>
                    <Label for="excludeListDatei">Datei</Label>
                    <Input type="file" name="file" id="excludeListDatei" 
                        bind:files={selectedFiles} 
                        valid={parsedFileIsValid === true} 
                        invalid={parsedFileIsValid === false} 
                        feedback={parsedFileValidationText} />                
                </FormGroup>          
            </Row>      
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" on:click={onCancel}>
                Abbrechen
            </Button>
            <Button color="primary" on:click={onOk}>
                Report anlegen
            </Button>
        </ModalFooter>
    </Modal>
</div>

<script>

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';
  
    import { 
        Row 
    } from '@sveltestrap/sveltestrap';

    import {
        Button,
        Modal,
        ModalBody,
        ModalFooter,
        ModalHeader,
    } from '@sveltestrap/sveltestrap';
    
    import { 
        read,
        utils
    } from "xlsx";

    import { 
        readFileAsync 
    } from '@/utils/readFileAsync';

    let open = false;

    let onOk = null;
    let onCancel = null;

    let selectedFiles = null;
    let loadedFile = null;
    let loadedFileName = null;
    let parsedFileIsValid = null;
    let parsedFileValidationText = null;
    let parsedFileContent = null;

    $: selectedFiles, loadSelectedFiles();
    $: loadedFile, parseLoadedFile();

    async function loadSelectedFiles() {
        if (!selectedFiles || selectedFiles.length < 1) {
            loadedFile = null;
            loadedFileName = null;
        } else {
            try {
                const contentBuffer = await readFileAsync(selectedFiles[0]);
                const xlsxWorkbook = read(contentBuffer);
                const firstWorksheet = xlsxWorkbook.Sheets[xlsxWorkbook.SheetNames[0]];
                loadedFile = utils.sheet_to_json(firstWorksheet, {
                    blankrows: false,
                    raw: true
                });
                loadedFileName = selectedFiles[0].name;
            } catch (e) {
                alert(e)
            }
        }
    }

    async function parseLoadedFile() {
        
        const SPALTEN = ['EAN', 'ITEM_CONDITION', 'SALES_CHANNEL'];

        if (!loadedFile) {
            parsedFileIsValid = null;
            parsedFileValidationText = null;
            parsedFileContent = null;
        } else {
            try {
                // Prüfe auf Daten in Datei
                if (Array.isArray(loadedFile) == false) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }
                // Prüfe auf Spalten in Datei
                for (const [i, value] of SPALTEN.entries()) {
                    if (!(value in loadedFile[0])) {
                        parsedFileIsValid = false;
                        parsedFileValidationText = `Spalte ${value} nicht gefunden.`;
                        parsedFileContent = null;
                        return;
                    }
                }
                // Prüfe auf nicht leere Zeilen in EAN
                let nonNullRows = loadedFile
                    .filter(x => x['EAN']);
                if (nonNullRows.length <= 0) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }   

                // Mappe die Eingabedatei in die Ausgabestruktur 
                const excludeItems = nonNullRows.map(row => ({
                    ean: row[SPALTEN[0]],
                    itemCondition: row[SPALTEN[1]],
                    salesChannel: row[SPALTEN[2]]
                }));

                // Es scheint so weit alles ok zu sein
                parsedFileIsValid = true;
                parsedFileValidationText = `${nonNullRows.length} Zeilen gefunden mit ${excludeItems.length} Auschlusszeilen`;
                parsedFileContent = { excludeItems };

            } catch (e) {
                alert(e);
            }
        }
    }

    export async function showDialog() {
        return new Promise((resolve, reject) => {
            open = true;
            
            onOk = () => {
                open = false;
                resolve(parsedFileContent ?? {})
            };
            onCancel = () => {                
                open = false;
                reject();
            };
        });
    }

</script>

