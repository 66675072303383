/**
 * Example usage
 * const randomId = generateRandomId('element_');
 * 
 * @param prefix
 */
export function generateRandomId(prefix) {
    const timestamp = Date.now().toString(36);
    const randomNum = Math.random().toString(36).substr(2);
    return prefix + timestamp + randomNum;
}