<Container>
    <Row>
        <FormGroup>
            <Label for="startDate">Startdatum</Label>
            <Input type="datetime-local" id="startDate" 
                bind:value={inputDocument.startDate} 
                valid={validationResultStart.valid === true} 
                invalid={validationResultStart.valid === false} 
                feedback={validationResultStart.invalidMessage} />
        </FormGroup>
        <FormGroup>
            <Label for="endDate">Enddatum</Label>
            <Input type="datetime-local" id="endDate" 
                bind:value={inputDocument.endDate} 
                valid={validationResultEnde.valid === true} 
                invalid={validationResultEnde.valid === false} 
                feedback={validationResultEnde.invalidMessage} />
        </FormGroup>        
    </Row>
</Container>

<script>

    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';
    
    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = {
        startDate: null,
        endDate: null
    };

    let validationResultStart = null;
    $: {
        let isValid = (inputDocument?.startDate?.length > 0);
        validationResultStart = {
            valid: isValid,
            invalidMessage: (isValid === false ? "ungültiges Datum" : null)
        };
    };

    let validationResultEnde = null;
    $: {
        let isValid = (inputDocument?.endDate?.length > 0);
        validationResultEnde = {
            valid: isValid,
            invalidMessage: (isValid === false ? "ungültiges Datum" : null)
        };
    };

    export let inputIsValid = false;
    $: {
        inputIsValid = validationResultStart?.valid && validationResultEnde?.valid;
    };

</script>