import Keycloak from "keycloak-js";
import { axiosClient}  from "@/clients/apdbClient";

import { writable } from 'svelte/store';

export class KeycloakAuthState {
    
    constructor() {

        /**
         * @type {boolean}
         */
        this.userIsLoggedIn = false;

        /**
         * @type {string}
         */
        this.userName = '';

        /**
         * @type {string[]}
         */
        this.authorities = [];

        /**
         * @type {string | null}
         */
        this.token = null;

    }

}

export class KeycloakAuthHandler {

    /**
     * @param {import("svelte/store").Writable<KeycloakAuthState>} authState 
     */
    constructor(authState) {
        /**
         * @type {import("svelte/store").Writable<KeycloakAuthState>}
         */
        this.authState = authState;
    }

    /**
     * @returns { Promise<void> }
     */
    async init() {

        try {

            /**
            * Keycloak Config laden
            */
            const keycloakConfig = await axiosClient
                .get("/api/public/keycloak")
                .then(response => response.data);

            /** Keycloak Client initialisieren
             * @see https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference 
             */
            this.keycloak = new Keycloak({
                url: keycloakConfig.serverUrl,
                realm: keycloakConfig.realm,
                clientId: "apdb"
            });

            /**
             * Login mit evtl. bestehdendem Cookie durchführen
             */
            let loggedIn = await this.keycloak.init({
                onLoad: 'check-sso',
                responseMode: 'query'
            });
            console.debug(`Keycloak User LoggedIn ${loggedIn}`)

        }
        catch (e) {
            console.error(e)
            alert(`Nutzerverwaltung ist aktuell nicht verfügbar, bitte wenden Sie sich an den IT-Support oder versuchen Sie es später nocheinmal.`)
            return;
        }

        /** internen Zustand aktualisieren */
        await this.updateState();

        // Refresh Timer
        this.refreshTimer = setInterval(
            async () => {
                if (this.keycloak?.authenticated) {
                    try {
                        const refreshed = await this.keycloak?.updateToken(70);
                        console.debug(`Keycloak Token ${refreshed ? '' : 'not '}refreshed`)
                    } catch (e) {
                        console.error(e)
                    }
                }
                await this.updateState();   
            }, 
            15000
        );

    }

    /**
     * @returns { Promise<void> }
     */
    async updateState() {
        
        let newState = new KeycloakAuthState();

        try {
            if (this.keycloak != null) {
                if (this.keycloak.authenticated) {
                    newState.userIsLoggedIn = true;
                    newState.token = this.keycloak.token ?? "";
                    newState.authorities = this.keycloak.realmAccess?.roles ?? [];
                    newState.userName = this.keycloak?.tokenParsed?.preferred_username ?? ""
                }
            }
        }
        catch (e) {
            console.log("authStore:updateState failed.")
        }

        if (newState.userIsLoggedIn) {
            axiosClient.defaults.headers['Authorization'] = `Bearer ${newState.token}`;
        } else {
            axiosClient.defaults.headers['Authorization'] = null;
        }

        this.authState.set(newState);

    }

    /**
     * @returns { Promise<void> }
     */
    async login() {
        this.keycloak?.login();
    }

    /**
     * @returns { Promise<void> }
     */
    async logout() {
        this.keycloak?.logout();
    }

}

export const authState = new KeycloakAuthState();
export const authStore = writable(authState);
export const authHandler = new KeycloakAuthHandler(authStore);
