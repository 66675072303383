<div>
    <Modal isOpen={open}>
        <div class="modal-header">
            <h5 class="modal-title">
                MISSING_VLB_PRICEDATA
            </h5>
        </div>
        <ModalBody>
            <Row>
                <FormGroup>
                    <Input type="checkbox" bind:checked={nurAktiveAngebote} label="nur aktive Angebote" />                
                </FormGroup>        
            </Row>      
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" on:click={onCancel}>
                Abbrechen
            </Button>
            <Button color="primary" on:click={onOk}>
                Report anlegen
            </Button>
        </ModalFooter>
    </Modal>
</div>

<script>

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';
  
    import { 
        Row 
    } from '@sveltestrap/sveltestrap';

    import {
        Button,
        Modal,
        ModalBody,
        ModalFooter,
        ModalHeader,
    } from '@sveltestrap/sveltestrap';

    let nurAktiveAngebote = true;

    let open = false;

    let onOk = null;
    let onCancel = null;

    export async function showDialog() {
        return new Promise((resolve, reject) => {
            open = true;
            onOk = () => {
                open = false;
                resolve({ nurAktiveAngebote })
            };
            onCancel = () => {                
                open = false;
                reject();
            };
        });
    }

</script>

