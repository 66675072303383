<Container>
    <Row>
        <FormGroup>
            <a href="{`samples/FixiertePreisKonfiguration.xlsx`}">
                Beispieldatei
            </a>
        </FormGroup>  
        <FormGroup>
            <Label for="mappenDatei">Datei</Label>
            <Input type="file" name="file" id="mappenDatei" 
                bind:files={selectedFiles} 
                valid={parsedFileIsValid === true} 
                invalid={parsedFileIsValid === false} 
                feedback={parsedFileValidationText} />                
        </FormGroup>        
    </Row>
</Container>

<script>

    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    import { 
        read,
        utils
    } from "xlsx";

    import { 
        readFileAsync 
    } from '@/utils/readFileAsync';

    let selectedFiles = null;
    let loadedFile = null;
    let loadedFileName = null;
    let parsedFileIsValid = null;
    let parsedFileValidationText = null;
    let parsedFileContent = null;

    $: selectedFiles, loadSelectedFiles();
    $: loadedFile, parseLoadedFile();

    async function loadSelectedFiles() {
        if (!selectedFiles || selectedFiles.length < 1) {
            loadedFile = null;
            loadedFileName = null;
        } else {
            try {
                const contentBuffer = await readFileAsync(selectedFiles[0]);
                const xlsxWorkbook = read(contentBuffer);
                const firstWorksheet = xlsxWorkbook.Sheets[xlsxWorkbook.SheetNames[0]];
                loadedFile = utils.sheet_to_json(firstWorksheet, {
                    blankrows: false,
                    raw: true
                });
                loadedFileName = selectedFiles[0].name;
            } catch (e) {
                alert(e)
            }
        }
    }

    async function parseLoadedFile() {
        
        const SPALTEN = ['EAN', 'Zustand'];

        if (!loadedFile) {
            parsedFileIsValid = null;
            parsedFileValidationText = null;
            parsedFileContent = null;
        } else {
            try {
                // Prüfe auf Daten in Datei
                if (Array.isArray(loadedFile) == false) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }
                // Prüfe auf Spalten in Datei
                for (const [i, value] of SPALTEN.entries()) {
                    if (!(value in loadedFile[0])) {
                        parsedFileIsValid = false;
                        parsedFileValidationText = `Spalte ${value} nicht gefunden.`;
                        parsedFileContent = null;
                        return;
                    }
                }
                // Prüfe auf nicht leere Zeilen in EAN
                let nonNullRows = loadedFile
                    .filter(x => x['EAN']);
                if (nonNullRows.length <= 0) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }   
                // Prüfe auf 13 Stellige Eingabe in Datei
                let countNon13 = nonNullRows
                    .filter(r => r[SPALTEN[0]]?.length != 13)
                    .length;
                if (countNon13 > 0) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Datei enthällt ${countNon13} ungültige EANs.`;
                    parsedFileContent = null;
                    return;
                }
                // Es scheint so weit alles ok zu sein
                parsedFileIsValid = true;
                parsedFileValidationText = `${nonNullRows.length} Zeilen gefunden`;
                parsedFileContent = {   
                    fileName: loadedFileName,             
                    items: nonNullRows.map(r => ({
                        ean: r[SPALTEN[0]],
                        itemCondition: r[SPALTEN[1]],
                        kkMinPrice: r['KK Min'],
                        kkPrice: r['KK'],
                        kkMaxPrice: r['KK Max']
                    }))
                };
            } catch (e) {
                alert(e);
            }
        }
    }

    export let inputDocument = {};
    $: {
        inputDocument = parsedFileContent;
    }

    export let inputIsValid = true;
    $: {
        inputIsValid = parsedFileIsValid;
    }

</script>