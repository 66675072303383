import { get } from "lodash";

export const toCsvString = (columns, rows) => {

    // Ignorierte Spalte rausfiltern
    columns = columns.filter(x => !(x.excelIgnore === true));

    // RESULT
    let lines = [];

    // HEADER
    let firstLine = "";
    for(let col of columns)
        firstLine += '"' + col.displayName + '";';
    lines.push(firstLine);

    // BODY
    for (let row of rows) {
        let rowLine = "";
        for(let col of columns) {
            let cellValue = get(row, col.propertyName, null);
            rowLine += '"' + (cellValue ?? '') + '";';
        }
        lines.push(rowLine);
    }

    // MERGE
    return lines.join('\n');
}

export const toSheetAndDownload = (columns, rows, fileName) => {

    const downloadBuffer = (buffer, fileName) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); 
        document.body.appendChild(link);
        link.click();
    }

    const buffer = toCsvString(columns, rows);
    downloadBuffer(buffer, fileName);

}