import NotFound from './routes/NotFound.svelte';
import ProductSearch from './routes/search/ProductSearch.svelte';
import ProductDetail from './routes/productdetail/ProductDetail.svelte';
import Reports from './routes/reports/Reports.svelte';
import ReportDocument from './routes/reports/ReportDocument.svelte';
import OrderReferences from "./routes/orderreferences/OrderReferences.svelte";
import OrderReferenceDetails from "./routes/orderreferences/OrderReferenceDetails.svelte";

import OrderReferences2 from "./routes/orderreferences/OrderReferences.svelte";
import OrderReferenceDetails2 from "./routes/orderreferences/OrderReferenceDetails.svelte";

import TaskList from "./routes/tasks/TaskList.svelte";
import TaskDetails from "./routes/tasks/TaskDetails.svelte";

export const routes = new Map();

routes.set('/search', ProductSearch);
routes.set('/products/:id', ProductDetail);

routes.set('/reports', Reports);
routes.set('/reports/:id', ReportDocument);

routes.set('/tasks', TaskList);
routes.set('/tasks/:id', TaskDetails);

routes.set('/orderreferences', OrderReferences);
routes.set('/orderreferences/:id', OrderReferenceDetails);

routes.set('/orderreferences2', OrderReferences2);
routes.set('/orderreferences2/:id', OrderReferenceDetails2);

routes.set('/', ProductSearch);
routes.set('*', NotFound);