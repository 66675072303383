<Container>
    <Row>
        <FormGroup>
            <Label for="startDate">Referenzdatum</Label>
            <Input type="datetime-local" id="startDate" 
                bind:value={inputDocument.refDate} 
                valid={validationResultReferenzDatum.valid === true} 
                invalid={validationResultReferenzDatum.valid === false} 
                feedback={validationResultReferenzDatum.invalidMessage} />
        </FormGroup>
    </Row>
</Container>

<script>

    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';
    
    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    import { DateTime } from "luxon";

    export let inputDocument = {
        refDate: DateTime.now()
            .startOf('second')
            .toISO({ includeOffset: false, suppressMilliseconds: true })
    };

    let validationResultReferenzDatum = null;
    $: {
        let isValid = (inputDocument?.refDate?.length > 0);
        validationResultReferenzDatum = {
            valid: isValid,
            invalidMessage: (isValid === false ? "ungültiges Datum" : null)
        };
    };

    export let inputIsValid = false;
    $: {
        inputIsValid = validationResultReferenzDatum?.valid;
    };

</script>