<div style="position: fixed; right: 1rem; bottom: 1rem; max-width: 50%; z-index:1099;">
    {#each axiosMessages as axiosMessage}
        <Alert isOpen={axiosMessage.isOpen} toggle={() => (axiosMessage.isOpen = false)} color="{axiosMessage.color}">
            <strong>{axiosMessage.text}</strong>
            {#if axiosMessage.detail}
                <div class="text-monospace" on:click={() => (axiosMessage.fullResponseIsOpen = !axiosMessage.fullResponseIsOpen)}>
                    {axiosMessage.detail}
                </div>
                <Collapse isOpen={axiosMessage.fullResponseIsOpen}>
                    {axiosMessage.fullResponse}
                </Collapse>
            {/if}
        </Alert>
    {/each}
</div>

<script>

    import { axiosClient } from "@/clients/apdbClient.js"

    import { Alert, Collapse } from '@sveltestrap/sveltestrap';

    let axiosMessages = [];
    // Add a response interceptor
    axiosClient.interceptors.response.use(
        function (response) {
            // Any status code that is within the range of 2xx cause this function to trigger
            if (!response?.config?.disableAxiosSuccess) {
                let msg = {
                    color: "primary",
                    text: `HTTP ${response.status} (${response.statusText})`,
                    isOpen: true,
                }
                axiosMessages = [msg, ...axiosMessages];
                setTimeout(() => msg.isOpen = false, 2000);
            }
            return response;
        },
        function (error) {
            if (!error?.config?.disableAxiosAlerts) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                let msg = {
                    color: "danger",
                    text: `HTTP ${error.response.status} (${error.response.statusText})`,
                    isOpen: true,
                    detail: error.response?.data?.message,
                    fullResponseIsOpen: false,
                    fullResponse: JSON.stringify(error.response, "", 4)
                }
                axiosMessages = [msg, ...axiosMessages];  
            }
            return Promise.reject(error);
        }
    );
    
    // Cleaning Up
    setInterval(() => axiosMessages = axiosMessages.filter(x => x.isOpen == true), 1000);
        
</script>