<Card class="mb-3">
    <CardHeader>
        Amazon Verkaufsrang
        <p class="fw-light m-0">Durchschnittsrang: {formatNumberAsZeroDecimalLocale(averageSalesRank)}</p>
    </CardHeader>
    <CardBody>
        <TimeSeriesChart 
            options={salesRankChartOptions}
            labels={chartLabels}
            datasets={salesRankChartDatasets} 
            on:areaOfInterestChanged={areaOfInterestChanged}/>          
    </CardBody>
</Card> 

<script>

    import { formatNumberAsZeroDecimalLocale } from '@/utils/format';
    
    import { DateTime, Duration } from 'luxon';
    import TimeSeriesChart from "@/components/TimeSeriesChart.svelte";

    import {
        Card,
        CardBody,
        CardHeader,
    } from '@sveltestrap/sveltestrap';

    import { getProductChart } from "@/clients/apdbClient";
    import { orderBy } from "lodash";

    const filterToDate = DateTime.now().toISO({ includeOffset: false });
    const filterFromDate = DateTime.now().minus(Duration.fromObject({days: 180})).toISO({ includeOffset: false });
    const chartLabels = [
        DateTime.fromISO(filterFromDate), 
        DateTime.fromISO(filterToDate), 
    ];

    const salesRankChartOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: 'dd.MM.yyyy',
                    unit: "day"                                    
                },
                title: {
                    display: true,
                    text: 'Datum'
                }                                
            }
        }, 
        elements: {
            point: {
                radius: 3,
                backgroundColor: 'red',
                pointStyle: 'triangle'
            }
        }
    };

    export let queryResultData;

    let chartDataSetA = null;
    async function areaOfInterestChanged(event) {
        chartDataSetA = await getProductChart(
            'salesRank',
            queryResultData?.amazonMasterData?.asin, 
            null, 
            event.detail.tMin, 
            event.detail.tMax
        );
    }

    $: queryResultData, () => {
        console.log("q");
        areaOfInterestChanged({ 
            event: { 
                detail: {
                    tMin: filterFromDate, 
                    tMax: filterToDate
                }
            }
        })
    };

    let salesRankChartDatasets = [];
    $: {

        let datasetA = orderBy((chartDataSetA?.queryResultData?.items || []), "binStart");

        salesRankChartDatasets = [{
            showLine: true,
            borderColor: 'blue',
            borderWidth: 1,
            data: datasetA
                ?.map(dp => ({
                    x: DateTime.fromISO(dp.binStart), 
                    y: dp.salesRank 
                }))
        }];
    };

    $: averageSalesRank = queryResultData?.amazonSalesRankData?.riSalesrankAverage || "n/a";

</script>