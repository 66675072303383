import { 
    utils, writeXLSX
} from "xlsx";

import {
    get
} from "lodash";

import {
    DateTime,
    Interval
} from "luxon";

/**
 * 
 * @param {Array} columns 
 * @param {Array} rows 
 * @returns 
 */
export const toSheet = (columns, rows) => {

    // Ignorierte Spalte rausfiltern
    columns = columns.filter(x => !(x.excelIgnore === true));

    // Sheet anlegen
    const ws = {
        '!ref': utils.encode_range(
            {
                c: 0, 
                r: 0
            }, 
            {
                c: columns.length - 1, 
                r: rows.length   // inklusive Kopfzeile
            }
        )
    }

    // Spalten bauen
    for (let [colIndex, column] of columns.entries()) {
        let cellIndex = utils.encode_cell({
            c: colIndex, 
            r: 0
        });
        ws[cellIndex] = {
            t: 's',
            v: get(column, 'displayName', '')
        };
    }

    // Zeilen schreiben
    for (let [rowIndex, row] of rows.entries()) {
        for (let [colIndex, column] of columns.entries()) {
            
            let cellIndex = utils.encode_cell({
                c: colIndex, 
                r: (rowIndex + 1)
            });

            // Wert und Formatierung
            let cellValue = get(row, column.propertyName, null);
            switch(column?.format) {
                case 'NUMBER_0_DECIMALS': {
                    ws[cellIndex] = {
                        t: 'n',
                        z: '0'
                    };
                    if (Number.isFinite(cellValue)) {
                        ws[cellIndex].v = cellValue;
                    }
                    break;
                }
                case 'NUMBER_2_DECIMALS': {
                    ws[cellIndex] = {
                        t: 'n',
                        z: '0.00'
                    };
                    if (Number.isFinite(cellValue)) {
                        ws[cellIndex].v = cellValue;
                    }
                    break;
                } 
                case 'DATETIME': {
                    ws[cellIndex] = {
                        t: 'n',                        
                        z: 'm/d/yy h:mm'
                    };                    
                    let diff = Interval
                        .fromDateTimes(
                            DateTime.fromISO("1899-12-30T00:00:00"), 
                            DateTime.fromISO(cellValue))
                        .toDuration('seconds')
                        .seconds;
                    let diffInDays = diff / (60 * 60 * 24);
                    if (Number.isFinite(diffInDays)) {
                        ws[cellIndex].v = diffInDays;
                    }
                    break;
                }                
                default: {
                    ws[cellIndex] = {
                        t: 's',
                        v: cellValue
                    };
                    break;
                }
            }

            // Style
            // NOT POSSIBLE WITH xlsxjs COMMUNITY 
            // https://gist.github.com/SheetJSDev/24b8acd317d01999d721b38de7c53021
            // ALTERNATIVE:
            // https://www.npmjs.com/package/exceljs
                          
        }
    }

    return ws;

}

/**
 * 
 * @param {*} columns 
 * @param {*} rows 
 * @param {*} fileName 
 */
export const toSheetAndDownload = (columns, rows, fileName) => {

    const exportToXLSX = () => {
        const ws1 = toSheet(columns, rows);
        const wb = { Sheets: { ws1 }, SheetNames: ['ws1'] };
        const excelBuffer = writeXLSX(wb, { bookType: 'xlsx', type: 'array' });
        return excelBuffer;
    }

    const downloadBuffer = (buffer, fileName) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); 
        document.body.appendChild(link);
        link.click();
    }

    const buffer = exportToXLSX();
    downloadBuffer(buffer, fileName);

}