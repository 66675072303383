/**
 * readFileAsync
 * @param {File} file 
 * @returns {Promise<ArrayBuffer> }
 */
export async function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            // @ts-ignore
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    })
}