import { writable, get } from 'svelte/store';

const KEY = "tableSettingStore";

let initValue = [];
try {
    let json = localStorage.getItem(KEY);
    json = JSON.parse(json);
    if (Array.isArray(json)) {
        initValue = json;
    }
} catch {
    // do nothing 
}

export const tableSettingStore = writable(initValue);

tableSettingStore.subscribe(current => {
    localStorage.setItem(KEY, JSON.stringify(current));
});

export function putTableSettings(s) {
    console.log("putTableSettings " + s?.id)
    
    tableSettingStore.update(currentList => {
        let itemIndex = currentList.findIndex(x => x.id == s.id);
        if (itemIndex >= 0) {
            currentList[itemIndex] = s;
        } else {
            currentList.push(s);
        }
        return currentList
    });

}

export function getTableSettings(id) {
    console.log("getTableSettings " + id)

    let currentList = get(tableSettingStore);
    let item = currentList.find(x => x.id == id);
    return item;

}