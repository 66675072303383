<Base>
    <svelte:fragment slot="main">
        {#if outputDocument}
            <Card class="mb-1">
                <CardHeader>
                    <CardTitle>
                        Ergebnis Liste
                    </CardTitle>                
                </CardHeader>
                <CardBody>
                    <TableWithPaginationAndSort responsive 
                        rows={(outputDocument?.items || [])} 
                        columns={tableColumnsOk} 
                        sortDirection="desc" 
                        sortByColumn="submitted" 
                        showDownloadButtonXlsx="true"
                        showPageSizeDropDown="true"
                        id="task-fallbackimport"
                        on:row-click={navigateToItem} />
                </CardBody>
            </Card>        
        {/if}
    </svelte:fragment>
</Base>
<script>

    import { push } from 'svelte-spa-router'

    import Base from "../../Base.svelte";    
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle, 
        CardSubtitle 
    } from '@sveltestrap/sveltestrap';

    export let outputDocument = null;

    let tableColumnsOk = [
        { displayName: 'EAN', propertyName: 'ean' },
        { displayName: 'Status', propertyName: 'updateOk'}
    ];

    function navigateToItem(onRowClickEvent) {
        const { ean } = onRowClickEvent?.detail?.row;
        if (ean) {
            push(`/products/${ean}`);
        }
    }

</script>