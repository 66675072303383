<Container fluid style="margin-bottom: 2rem;">
    <Row>
        <Column>
            <slot name="header">
                <h1>{ title }</h1> 
            </slot>
        </Column>
    </Row> 
    <Row>
        <Column>
            <slot name="main" />   
        </Column>
    </Row>        
</Container>   

<AxiosLoadingIndicator />


<script>

    import { Column, Container, Row } from '@sveltestrap/sveltestrap';
    import AxiosLoadingIndicator from "@/components/AxiosLoadingIndicator.svelte";

    export let title = "";

</script>