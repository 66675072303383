<Base>
    <svelte:fragment slot="main">
        {#if outputDocument}
            <Card class="mb-1">
                <CardHeader>
                    <CardTitle>
                        OK Liste
                    </CardTitle>                
                </CardHeader>
                <CardBody>
                    <TableWithPaginationAndSort responsive 
                        rows={(outputDocument?.okItems || [])} 
                        columns={tableColumnsOk} 
                        sortDirection="desc" 
                        sortByColumn="submitted" 
                        showDownloadButtonXlsx="true"
                        showPageSizeDropDown="true"
                        id="task-fixedpriceconfiguration-oklist"
                        on:row-click={navigateToItem}>
                        <div slot="custom-slot-1" let:row={row} let:column={column}>
                            { row[column.propertyName] ? row[column.propertyName] : "-" }
                        </div>
                    </TableWithPaginationAndSort>
                </CardBody>
            </Card>        
            <Card class="mb-1">
                <CardHeader>
                    <CardTitle>
                        Fehlerliste
                    </CardTitle>                
                </CardHeader>
                <CardBody>
                    <TableWithPaginationAndSort responsive 
                        rows={(outputDocument?.errorItems || [])} 
                        columns={tableColumnsError} 
                        sortDirection="desc" 
                        sortByColumn="submitted" 
                        showDownloadButtonXlsx="true"
                        showPageSizeDropDown="true"
                        id="task-fixedpriceconfiguration-errorlist"
                        on:row-click={navigateToItem} />
                </CardBody>
            </Card>
        {/if}
    </svelte:fragment>
</Base>
<script>

    import { push } from 'svelte-spa-router'

    import Base from "../../Base.svelte";    
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle, 
        CardSubtitle 
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

    let tableColumnsOk = [
        { displayName: 'EAN', propertyName: 'ean' },
        { displayName: 'Zustand', propertyName: 'itemCondition'},
        { displayName: 'KKMin', propertyName: 'kkMinPrice', customSlot: '1'},
        { displayName: 'KK', propertyName: 'kkPrice', customSlot: '1'},
        { displayName: 'KKMax', propertyName: 'kkMaxPrice', customSlot: '1'}
    ];

    let tableColumnsError = [
        { displayName: 'EAN', propertyName: 'ean' },
        { displayName: 'Zustand', propertyName: 'itemCondition'},
        { displayName: 'Fehlermeldung', propertyName: 'errorMessage' }
    ];

    function navigateToItem(onRowClickEvent) {
        const { ean } = onRowClickEvent?.detail?.row;
        if (ean) {
            push(`/products/${ean}`);
        }
    }

</script>