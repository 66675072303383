{#if filterGlobalEnabled}
    <div class="mb-2 d-flex flex-row-reverse">
        <Input 
            class="w-25"
            style="text-align: right;"
            type="text" 
            placeholder="globale Suche" 
            bind:value={filterGlobalSearchValue} 
        />
    </div>
{/if}
<Table {...$$restProps}>
    <thead>
        <tr>
            {#if showLineNumber}
                <th style="width: 1px;" on:click={() => onColumnHeaderClick()}>
                    #
                </th>
            {/if}
            {#each columns as column}
                {@const columnStyle = column.style ? column.style : ''}
                {@const widthStyle = column.width ? `min-width: ${column.width}; max-width: ${column.width};` : ''}
                {@const elemId = generateRandomId('table-col-') }
                <th style="{columnStyle};{widthStyle};" on:click={() => onColumnHeaderClick(column)} id="{elemId}">
                    { column.displayName }
                    {#if sortByColumn == column.propertyName }
                        <Icon name={ sortDirection == 'asc' ? 'caret-up' : 'caret-down'} />
                    {/if}
                    <Tooltip target="{elemId}" placement="bottom">
                        { column.displayName }
                    </Tooltip>
                </th>
            {/each}
        </tr>
    </thead>
    <tbody>
        {#each displayRows as row}
            <tr on:click={() => onRowClick(row)} style="cursor:pointer">
                {#if showLineNumber}
                    <th style="width: 1px;" scope="row">{ row.__row_idx }</th>
                {/if}
                {#each columns as column}
                    {@const columnStyle = column.style ? column.style : ''}
                    {@const cellStyle = column.cellStyle ? column.cellStyle(row) : ''}
                    {@const widthStyle = column.width ? `min-width: ${column.width}; max-width: ${column.width};` : ''}
                    {@const cellValue = get(row, column.propertyName, null)}
                    <td style="{columnStyle};{cellStyle};{widthStyle};">
                        {#if column?.format}
                            {#if column?.format == 'DATETIME'}
                                { (cellValue != null) ? formatIsoDateStringToLocalDateTime(cellValue) : "-" }
                            {:else if column?.format == 'DATE'}
                                { (cellValue != null) ? formatIsoDateStringToLocalDate(cellValue) : "-" }
                            {:else if column?.format == 'NUMBER_0_DECIMALS'}
                                { (cellValue != null) ? formatNumberAsZeroDecimalLocale(cellValue) : "-" }
                            {:else if column?.format == 'NUMBER_2_DECIMALS'}
                                { (cellValue != null) ? formatNumberAsTwoDecimalLocale(cellValue) : "-" }
                            {:else if column?.format == 'PERCENT'}
                                { (cellValue != null) ? formatNumberAsPercent(cellValue * 100.0) : "-" }
                            {:else if column?.format == 'BOOLEAN'}
                                { (cellValue != null) ? formatBoolean(cellValue) : "-" }
                            {:else}
                                { cellValue }
                            {/if}
                        {:else}    
                            <!-- https://github.com/sveltejs/svelte/issues/6493 -->
                            {#if column?.customSlot == '1'}  
                                <slot row={row} column={column} name='custom-slot-1'></slot>
                            {:else if column?.customSlot == '2'}
                                <slot row={row} column={column} name='custom-slot-2'></slot>
                            {:else if column?.customSlot == '3'}
                                <slot row={row} column={column} name='custom-slot-3'></slot>
                            {:else if column?.customSlot == '4'}
                                <slot row={row} column={column} name='custom-slot-4'></slot>
                            {:else if column?.customSlot == '5'}
                                <slot row={row} column={column} name='custom-slot-5'></slot>
                            {:else}
                                { cellValue }
                            {/if}
                        {/if}
                    </td>
                {/each}
            </tr>
        {/each}
    </tbody>
</Table>

<Container fluid class="p-0">
    <Row class="mt-2">
        <Col xs="auto">
            <ButtonGroup>
                {#if showPageSizeDropDown}
                    <Input type="select" bind:value={pageSize} style="padding-top: 0.125rem; padding-bottom: 0.125rem;">
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                        <option value={9999}>9999</option>
                    </Input>
                {/if}
            </ButtonGroup>
        </Col>        
        <Col>
            {#if numPages != null && numPages > 0}
                <Pagination size="sm" class="d-flex justify-content-center" listClassName="m-0">
                    <PaginationItem disabled={ page <= 1 }>
                        <PaginationLink first href="#" on:click={(e) => {e.preventDefault(); page = 1;}} />
                    </PaginationItem>
                    <PaginationItem disabled={ page <= 1 }>
                        <PaginationLink previous href="#" on:click={(e) => {e.preventDefault(); page--;}} />
                    </PaginationItem>
                    {#each paginationLinks as pl}
                        <PaginationItem>
                            <PaginationLink href="#" on:click={(e) => {e.preventDefault(); page = pl;}} >
                                <span class:active={page == pl}>{pl}</span>
                            </PaginationLink>
                        </PaginationItem>
                    {/each}
                    <PaginationItem disabled={ page >= numPages }>
                        <PaginationLink next href="#" on:click={(e) => {e.preventDefault(); page++;}} />
                    </PaginationItem>
                    <PaginationItem disabled={ page >= numPages }>
                        <PaginationLink last href="#" on:click={(e) => {e.preventDefault(); page = numPages;}} />
                    </PaginationItem>
                </Pagination>
            {/if}
        </Col>
        <Col xs="auto">
            <ButtonGroup>
                {#if showDownloadButtonXlsx}
                    <Button color="secondary" size="sm" on:click={() => onDownloadAsExcel() }>
                        <Icon name="download" id="button-download" />
                        <Tooltip target="button-download">XLSX-Download</Tooltip>
                    </Button>   
                {/if}
                {#if showDownloadButtonCsv}
                    <Button color="secondary" size="sm" on:click={() => onDownloadAsCsv() }>
                        <Icon name="download" id="button-download" />
                        <Tooltip target="button-download">CSV-Download</Tooltip>
                    </Button>   
                {/if}
            </ButtonGroup>
        </Col>
    </Row>
</Container>

<style>
    th, td {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
</style>

<script>
    
    import { 
        createEventDispatcher,
        onMount 
    } from 'svelte';

	const dispatch = createEventDispatcher();

    import { 
        formatIsoDateStringToLocalDateTime,
        formatIsoDateStringToLocalDate,
        formatNumberAsTwoDecimalLocale,
        formatNumberAsZeroDecimalLocale,
        formatNumberAsPercent,
        formatBoolean
    } from '@/utils/format';

    import { generateRandomId } from '@/utils/generateRandomId';
    
    import {
        toSheetAndDownload as toXlsx
    } from "./table-xlsx-util";

    import {
        toSheetAndDownload as toCsv
    } from "./table-csv-util";

    import { 
        putTableSettings,
        getTableSettings
    } from "./table-settings";
    
    import { 
        get,
        orderBy 
    } from 'lodash';

    import {
        Pagination, 
        PaginationItem, 
        PaginationLink,       
        Table,
        Icon,
        Col, 
        Container, 
        Row,
        ButtonGroup,
        Button,
        Tooltip,
        Input
    } from '@sveltestrap/sveltestrap';

    /**
     * Zeilen
     * @param {Object[]} rows - die Zeilen für die Tabelle
     */
    export let rows = undefined;

    /**
     * Spalten
     * @param {Object[]} columns - die Spalten für die Tabelle
     * @param {string} columns[].propertyName 
     * @param {string} columns[].displayName 
     * @param {string} columns[].customSlot 
     * @param {(DATETIME|DATE|NUMBER_0_DECIMALS|NUMBER_2_DECIMALS|PERCENT|BOOLEAN)} columns[].format         
     * @param {string} columns[].style 
     * @param {Function} columns[].cellStyle Funktion die den CellStyle berechnet
     * @param {boolean} columns[].excelIgnore Funktion die den CellStyle berechnet
     * @param {String} columns[].width Kombination aus Min-Width und Max-Width
     */
    export let columns = undefined;

    /**
     * Zeilenspalte anzeigen
     */
    export let showLineNumber = true;

    /**
     * Download Button XLSX anzeigen
     */
    export let showDownloadButtonXlsx = false;

    /**
     * Download Button CSV anzeigen
     */
    export let showDownloadButtonCsv = false;

    /**
     * Download Button anzeigen
     */
    export let showPageSizeDropDown = false;

    /**
     * Sortierschlüssel
     */
    export let sortByColumn = undefined;

    /**
     * Sortierrichtung
     * @param {(asc|desc)} sortDirection
     */
    export let sortDirection = undefined;

    /**
     * globale Filterung
     */
    export let filterGlobalEnabled = false;
    export let filterGlobalSearchValue = "";

    /**
     * ID der Tabelle z.B. um Einstellungen zu speichern
    */
    export let id = null;

    /**
     * Click auf Spalteheader ändert die Sortierung
     * @param column
     */
    function onColumnHeaderClick(column) {
        
        // aktuelle Spalte
        if (sortByColumn && sortByColumn == column?.propertyName) {
            sortDirection = (sortDirection == 'asc') ? 'desc' : 'asc';
            return;
        }

        // andere Spalte
        if (column?.propertyName) {
            sortByColumn = column?.propertyName;
            sortDirection = 'asc';
            return;
        }

        // keine Spalte
        sortByColumn = undefined;
        sortDirection = undefined;
    }

    /**
     * Click auf eine Zeile
     * @param row
     */
    function onRowClick(row) {
        dispatch('row-click', { row });
    }

    // Download as Excel
    function onDownloadAsExcel() {
        toXlsx(columns, sortedRows, "download.xlsx");
    }
    function onDownloadAsCsv() {
        toCsv(columns, rows, "download.csv");
    }

    // Pagination
    export let page = 1;
    export let pageSize = 10;

    let numPages;
    let paginationLinks;
    
    // Anzeige zusammenbauen
    let sortedRows = undefined;
    let displayRows = undefined;
    $: {
        
        sortedRows = [...rows];

        // Filterung
        if (filterGlobalEnabled && filterGlobalSearchValue) {
            sortedRows = sortedRows.filter(row => {
                let matchingColumn = columns.find(c => {
                    let cellValue = get(row, c.propertyName);
                    return (cellValue && (String(cellValue).indexOf(filterGlobalSearchValue) >= 0));
                });
                return (matchingColumn);
            })
        }

        // Sortieren
        if (sortByColumn) {
            sortedRows = orderBy(
                sortedRows, 
                (x) => get(x, sortByColumn, ''), 
                (sortDirection?.toLowerCase() == 'asc' ? 'asc' : 'desc')
            );
        }

        // Indexieren
        sortedRows = sortedRows
            .map((row, index) => {
                return { 
                    __row_idx: index + 1, 
                    ...row 
                };
            });

        // Pageinieren
        numPages = Array.isArray(sortedRows) 
            ? Math.ceil(sortedRows.length / pageSize) 
            : null;
        
        if (numPages == null) {
            page = null;
        }
        else {
            if (page < 1)
                page = 1;
            if (page > numPages) 
                page = numPages;
        }

        paginationLinks = [];
        if (page >= 3) paginationLinks.push(page - 2);
        if (page >= 2) paginationLinks.push(page - 1);
        paginationLinks.push(page);
        if (page <= (numPages - 1)) paginationLinks.push(page + 1);
        if (page <= (numPages - 2)) paginationLinks.push(page + 2);

        displayRows = Array.isArray(sortedRows) 
            ? sortedRows.slice((page - 1) * pageSize, page  * pageSize) 
            : null;

    }
        
    let settingsLoaded = false;
    // Einstellungen laden
    onMount(async () => {
        if (id) {
            let storedSettings = getTableSettings(id);
            if (storedSettings) {
                sortByColumn = storedSettings.sortByColumn;
                sortDirection = storedSettings.sortDirection;
                page = storedSettings.page;
                pageSize = storedSettings.pageSize;
                filterGlobalSearchValue = storedSettings.filterGlobalSearchValue;
            }
            settingsLoaded = true;
        }
	});

    // Einstellungen speichern
    $: {
        if (settingsLoaded && id) {
            if (sortByColumn || sortDirection || page || pageSize || filterGlobalSearchValue) {
                putTableSettings({
                    id, 
                    sortByColumn,
                    sortDirection,
                    page,
                    pageSize,
                    filterGlobalSearchValue
                });
            }
        }
    }

</script>