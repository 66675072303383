<Card class="mb-3">
    <CardHeader>
        {productTitle}
    </CardHeader>
    <CardBody>
        <Container fluid >
            <Row>
                <Col class="p-0 d-flex" style="flex: 0 0 128px; justify-content: center;">
                    {#if productImageSrc}
                        <Image alt="Product Image" src={productImageSrc} style="max-height: 128px; max-width: 128px; height: 100%;" />
                    {:else}
                        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128">
                            <rect fill="#ddd" width="128" height="128"/>
                        </svg>
                    {/if}
                </Col>
                <Col class="p-0">
                    <Container fluid>
                        <Row>
                            <Col sm="4" md="3">Autor: </Col>
                            <Col>
                                <span>{productAutorVorname ?? 'n/a'}</span>
                                <span>{productAutorNachname ?? 'n/a'}</span>
                            </Col>
                        </Row>                    
                        <Row>
                            <Col sm="4" md="3">EAN: </Col>
                            <Col>{productEAN}</Col>
                        </Row>
                        <Row>
                            <Col sm="4" md="3">ASIN:</Col>
                            <Col>
                                <span style="white-space:nowrap;">
                                    {productASIN}
                                    <a href={"https://www.amazon.de/gp/product/" + productASIN} target="_blank" title={productASIN}>
                                        <Icon name="folder-symlink" />
                                    </a>                                
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="4" md="3">LP: </Col>
                            <Col>
                                {productLP}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="4" md="3">LP geb.: </Col>
                            <Col>
                                {productLPGebunden}
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>                  
    </CardBody>
</Card>

<script>
    
    import {
        Card,
        CardBody,
        CardHeader,
        Icon,
        Image,
        Col, 
        Container, 
        Row
    } from '@sveltestrap/sveltestrap';

    import {
        orderBy
    } from "lodash";

    import { 
        formatNumberAsTwoDecimalLocale 
    } from '@/utils/format';

    export let queryResultData;

    let productImageSrc;
    $: {
        let vlbMediaItems = queryResultData
            ?.vlbMediaData
            ?.items
            ?.filter(x => x.resourceMode == '03')
            ?? [];
        vlbMediaItems = orderBy(vlbMediaItems, "contentType", "asc");
        productImageSrc = vlbMediaItems[0]?.url;
    }
    $: productTitle = queryResultData?.amazonMasterData?.title;
    $: productAutorVorname = queryResultData?.vlbMasterData?.autorVorname;
    $: productAutorNachname = queryResultData?.vlbMasterData?.autorNachname;
    $: productEAN = queryResultData?.amazonMasterData?.ean;
    $: productASIN = queryResultData?.amazonMasterData?.asin;
    $: productLP = formatNumberAsTwoDecimalLocale(queryResultData?.vlbPriceData?.de?.amount) ?? 'n/a';
    
    let productLPGebunden;
    $: {
        let f = queryResultData?.vlbPriceData?.de?.fixedprice;
        if (f === true) 
            productLPGebunden = "Ja";
        else if (f === false) 
            productLPGebunden = "Nein";
        else
            productLPGebunden = "n/a";
    }
    
</script>