<Container>
    <Row>
        <FormGroup>
            <a href="{`samples/VlbScoreUpload.xlsx`}">
                Beispieldatei
            </a>
        </FormGroup>  
        <FormGroup>
            <Label for="mappenDatei">Datei</Label>
            <Input type="file" name="file" id="mappenDatei" 
                bind:files={selectedFiles} 
                valid={parsedFileIsValid === true} 
                invalid={parsedFileIsValid === false} 
                feedback={parsedFileValidationText} />                
        </FormGroup>        
    </Row>
</Container>

<script>

    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    import { 
        read,
        utils
    } from "xlsx";

    import { 
        readFileAsync 
    } from '@/utils/readFileAsync';

    let selectedFiles = null;
    let loadedFile = null;
    let loadedFileName = null;
    let parsedFileIsValid = null;
    let parsedFileValidationText = null;
    let parsedFileContent = null;

    $: selectedFiles, loadSelectedFiles();
    $: loadedFile, parseLoadedFile();

    async function loadSelectedFiles() {
        if (!selectedFiles || selectedFiles.length < 1) {
            loadedFile = null;
            loadedFileName = null;
        } else {
            try {
                const contentBuffer = await readFileAsync(selectedFiles[0]);
                const xlsxWorkbook = read(contentBuffer);
                const firstWorksheet = xlsxWorkbook.Sheets[xlsxWorkbook.SheetNames[0]];
                loadedFile = utils.sheet_to_json(firstWorksheet, {
                    blankrows: false,
                    raw: true
                });
                loadedFileName = selectedFiles[0].name;
            } catch (e) {
                alert(e)
            }
        }
    }

    async function parseLoadedFile() {
        
        const SPALTEN = ['EAN'];

        if (!loadedFile) {
            parsedFileIsValid = null;
            parsedFileValidationText = null;
            parsedFileContent = null;
        } else {
            try {

                // Prüfe auf Daten in Datei
                if (Array.isArray(loadedFile) == false) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }

                // Prüfe auf Spalten in Datei
                for (const [i, value] of SPALTEN.entries()) {
                    if (!(value in loadedFile[0])) {
                        parsedFileIsValid = false;
                        parsedFileValidationText = `Spalte ${value} nicht gefunden.`;
                        parsedFileContent = null;
                        return;
                    }
                }

                // Prüfe auf nicht leere Zeilen in EAN
                let nonNullRows = loadedFile
                    .filter(x => x['EAN']);
                if (nonNullRows.length <= 0) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }   

                // Prüfe auf nicht 13-stellige EANs
                let non13eans = nonNullRows.filter(x => x['EAN']?.length != 13).map(x => `'${x['EAN']}'`);
                if (non13eans.length > 0) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Datei enthält EANs mit ungültiger Länge: ${non13eans.join(',')}`;
                    parsedFileContent = null;
                    return;
                } 

                // Es scheint so weit alles ok zu sein
                parsedFileIsValid = true;
                parsedFileValidationText = `${nonNullRows.length} Zeilen gefunden`;
                parsedFileContent = {   
                    fileName: loadedFileName,             
                    items: nonNullRows.map(x => ({ean: x['EAN']}))
                };

            } catch (e) {
                alert(e);
            }
        }
    }

    export let inputDocument = {};
    $: {
        inputDocument = parsedFileContent;
    }

    export let inputIsValid = true;
    $: {
        inputIsValid = parsedFileIsValid;
    }

</script>