<Base>
    <svelte:fragment slot="main">
    {#if inputDocument} 
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Parameter
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Label for="startDate">Importdatum</Label>
                    <Input type="date" id="startDate" 
                        value={inputDocument.importDatum} 
                        disabled />
                </FormGroup>
            </CardBody>
        </Card>    
    {/if}
    {#if outputDocument}
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    zu VLB neu hinzugefügte Titel
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={(outputDocument?.createdItems || [])} 
                    columns={importedItemsColumns} 
                    sortDirection="desc" 
                    sortByColumn="submitted" 
                    showDownloadButtonXlsx="true"
                    showPageSizeDropDown="true"
                    on:row-click={navigateToItem} />
            </CardBody>
        </Card>
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    bei VLB geänderte Titel
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={(outputDocument?.modifiedItems || [])} 
                    columns={importedItemsColumns} 
                    sortDirection="desc" 
                    sortByColumn="submitted" 
                    showDownloadButtonXlsx="true"
                    showPageSizeDropDown="true"
                    on:row-click={navigateToItem} />
            </CardBody>
        </Card>
    {/if}
    </svelte:fragment>
</Base>

<script>
 
    import { push } from 'svelte-spa-router'
    import Base from "../../Base.svelte";    
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    
    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle, 
        CardSubtitle 
    } from '@sveltestrap/sveltestrap';

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

    function navigateToItem(onRowClickEvent) {
        const { ean } = onRowClickEvent?.detail?.row;
        if (ean) {
            push(`/products/${ean}`);
        }
    }

    let importedItemsColumns = [
        { 
            displayName: 'EAN', 
            propertyName: 'ean' 
        }
    ];

</script>