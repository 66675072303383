<svelte:component 
    this={resultComponent} 
    bind:inputDocument={inputDocument}
    bind:outputDocument={outputDocument} />

<script>

    import { onMount } from 'svelte'; 

    import { 
        getTaskList,
        getTaskInputDocument, 
        getTaskOutputDocument
    } from "@/clients/apdbClient";

    import { TASK_TYPES } from "./TaskTypes.js";

    export let params = {};

    let task = null;
    let inputDocument = null;
    let outputDocument = null;
    let resultComponent = null;

    onMount(async () => {
        task = await getTaskList()
            .then(({queryResultData}) => queryResultData)
            .then(allTasks => allTasks.find(x => x.id == params.id));
        resultComponent = TASK_TYPES
            .find(x => x.type == task.typ)?.resultComponent;
        inputDocument  = await getTaskInputDocument(params.id)
            .then(({queryResultData}) => queryResultData);
        outputDocument = await getTaskOutputDocument(params.id)
            .then(({queryResultData}) => queryResultData);
    });

</script>