<Card class="mb-3">
    <CardHeader>
        Fallbackwerte
    </CardHeader>
    <CardBody>
        <TableWithPaginationAndSort 
            responsive 
            rows={tableRows} 
            columns={tableColumns} 
            sortDirection="asc" 
            sortByColumn="fallbackKey">
            <div slot="custom-slot-1" let:row={row} let:column={column}>
                { row[column.propertyName] != null ? row[column.propertyName] : "-" }
            </div>
            <div slot="custom-slot-2" let:row={row} let:column={column}>
                { row[column.propertyName] ? formatIsoDateStringToLocalDateTime(row[column.propertyName]) : "-" }
            </div>            
        </TableWithPaginationAndSort>        
    </CardBody>
</Card> 

<script>

    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import {
        Card,
        CardBody,
        CardHeader
    } from '@sveltestrap/sveltestrap';

    import { 
        formatIsoDateStringToLocalDateTime 
    } from '@/utils/format';

    import { 
        getProductFallbackValues
    } from "@/clients/apdbClient";

    export let ean;

    let productFallbackValues;
   
    $: tableRows = productFallbackValues ?? [];

    let tableColumns = [
        { displayName: 'Zustand', propertyName: 'itemCondition', customSlot: '1' },
        { displayName: 'Key', propertyName: 'fallbackKey' },
        { displayName: 'Value', propertyName: 'fallbackValue', customSlot: '1' },
        { displayName: 'Value Bool', propertyName: 'fallbackValueBool', customSlot: '1' },
        { displayName: 'Geändert', propertyName: 'modifiedAt', customSlot: '2' }
    ];  

    $: {
        ean;
        getProductFallbackValues({ean})
            .then(p => {
                productFallbackValues = p?.queryResultData?.items ?? [];
            })
    }

</script>