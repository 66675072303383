<Base>
    <svelte:fragment slot="main">
    {#if inputDocument} 
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Eingabe
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Label for="startDate">Referenzdatum</Label>
                    <Input type="datetime-local" id="startDate" 
                        value={inputDocument.refDate} 
                        readonly />
                </FormGroup>
            </CardBody>
        </Card>    
    {/if}
    {#if outputDocument}
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Ausgabe
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <Row>
                    <p>keine Ausgabe</p>
                </Row>
            </CardBody>
        </Card>
    {/if}
    </svelte:fragment>
</Base>

<script>
 
    import Base from "../../Base.svelte";    
    
    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle, 
        CardSubtitle 
    } from '@sveltestrap/sveltestrap';

    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

</script>