import { DateTime } from 'luxon';

/**
 * formatiert eine Zahl auf zwei feste Nachkommastellen
 * @param {Number} n 
 * @returns 
 */
export const formatNumberAsTwoDecimal = (n) => {
    if (typeof n != 'number') 
        return null;
    else
        return n.toFixed(2);
}

/**
 * formatiert einen ISO Datumsstring als lokales Datum mit Uhrzeit
 * @param {String} isoDateString 
 */
export const formatIsoDateStringToLocalDateTime = (isoDateString) => {
    const DATETIME_SHORT = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    };
    return DateTime
        .fromISO(isoDateString)
        .toLocaleString(DATETIME_SHORT);
}

/**
 * formatiert einen ISO Datumsstring als lokales Datum
 * @param {String} isoDateString 
 */
 export const formatIsoDateStringToLocalDate = (isoDateString) => {
    const DATE_SHORT = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };
    return DateTime
        .fromISO(isoDateString)
        .toLocaleString(DATE_SHORT);
}

/**
 * formatiert ein Boolean Value nach "✓", "⨯" oder "?"
 */
export const formatBoolean = (v) => {
    if (v === true)
        return "✓"
    if (v === false)
        return "⨯"
    return "?"        
}

/**
 * formatiert eine Zahl auf null Nachkommastellen und lokaler Formatierung
 * @param {Number | "Infinity"} n 
 * @returns 
 */
 export const formatNumberAsZeroDecimalLocale = (n) => {
    if (n == "Infinity") 
        return "∞";
    return n?.toLocaleString(undefined, {        
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}

/**
 * formatiert eine Zahl auf zwei Nachkommastellen und lokaler Formatierung
 * @param {Number | "Infinity"} n 
 * @returns 
 */
 export const formatNumberAsTwoDecimalLocale = (n) => {
    if (n == "Infinity") 
        return "∞";     
    return n?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

/**
 * formatiert eine Zahl auf zwei Nachkommastellen und lokaler Formatierung
 * @param {Number} n 
 * @returns 
 */
 export const formatNumberAsPercent = (n) => {
    return (n / 100.0)?.toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}
