<svelte:head>
    <link rel="stylesheet" href="vendor/bootstrap/bootstrap.min.css" />
    <link rel="stylesheet" href="vendor/bootstrap-icons/bootstrap-icons.css" />
</svelte:head>

<Navbar color="light" light expand="xs">
    <NavbarBrand href="/">APDB</NavbarBrand>    
    <Nav navbar>
        {#if $authStore.authorities.indexOf("APDB_SEARCH") >= 0}
        <NavItem>
            <NavLink href="/#/search" active={$location == '/search'}>
                <Icon name="search" id="navbar-search" />
                <Tooltip target="navbar-search">Suche</Tooltip>
            </NavLink>
        </NavItem>   
        {/if}  
        {#if $authStore.authorities.indexOf("APDB_REPORTS_RO") >= 0}
        <NavItem>
            <NavLink href="/#/reports" active={$location == '/reports'}>
                <Icon name="table" id="navbar-reports" />
                <Tooltip target="navbar-reports">Berichte</Tooltip>
            </NavLink>
        </NavItem>         
        {/if}  
        {#if $authStore.authorities.indexOf("APDB_BATCHINPUT_RO") >= 0}
        <NavItem>
            <NavLink href="/#/tasks" active={$location == '/tasks'}>
                <Icon name="list-task" id="navbar-tasks" />
                <Tooltip target="navbar-tasks">Tasks</Tooltip>
            </NavLink>
        </NavItem>         
        {/if}          
        {#if $authStore.authorities.indexOf("APDB_ORDER_REFERENCE_RO") >= 0}
        <NavItem>
            <NavLink href="/#/orderreferences" active={$location == '/orderreferences'}>
                <Icon name="box-seam" id="navbar-orderreferences" class="text-primary"  />
                <Tooltip target="navbar-orderreferences">Bestellreferenzen</Tooltip>
            </NavLink>
        </NavItem>          
        {/if}          
        
            {#if $authStore.userIsLoggedIn} 
            <NavItem>                           
                <NavLink on:click={onLogoutClick}>
                    <Icon name="box-arrow-right" id="navbar-logout" />
                    <Tooltip target="navbar-logout">Abmelden</Tooltip>
                </NavLink>
            </NavItem>  
            {:else}
            <Button on:click={onLoginClick} color="primary">
                <Icon name="key-fill" id="navbar-login" />
                Anmelden
                <Tooltip target="navbar-login">Anmelden</Tooltip>
            </Button>
               
            {/if}
           
    </Nav>
</Navbar>

<AxiosAlerts />

{#if $authStore.userIsLoggedIn} 
    <Router {routes} on:routeLoaded={routeLoaded} />
{/if}

<Navbar color="light" light expand="md" class="fixed-bottom">
    <Nav class="ms-auto" navbar>
        <NavItem class="mr-2">
            Version: {V} Build: {B}
        </NavItem>
    </Nav>    
</Navbar>

<script>

    import { authStore, authHandler } from "@/stores/authStore"
    
    import Router, { push, location } from "svelte-spa-router";
    import { routes } from "./routes";

    import { formatIsoDateStringToLocalDateTime } from "@/utils/format";

    import AxiosAlerts from "@/components/AxiosAlerts.svelte";

    import {
        Navbar,
        NavbarBrand,
        Nav,
        NavItem,
        NavLink,
        Icon,
        Tooltip,
        Button
    } from '@sveltestrap/sveltestrap';

    let isOpen = false;

    function routeLoaded(event) {
        isOpen = false;
    }

    function onLoginClick() {
        authHandler.login();
    }

    function onLogoutClick() {
        authHandler.logout();
    }

    let V = "GLOBALS_PACKAGE_VERSION";
    let B = formatIsoDateStringToLocalDateTime("GLOBALS_BUILD_TIMESTAMP");

</script>
