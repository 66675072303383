<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<Base>
    <svelte:fragment slot="main">
        {#if queryResultData}
            <Card>
                <CardHeader>
                    <Row>
                        <Col class="d-grid align-items-center">
                            <CardTitle class="mb-0">
                                Bestellreferenzen                        
                            </CardTitle>
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="primary" size="sm" on:click={() => triggerNew() }>
                                    <Icon name="plus" />
                                </Button>      
                                <Button color="secondary" size="sm" on:click={() => refreshList() }>
                                    <Icon name="arrow-repeat" />
                                </Button>                             
                            </ButtonGroup>
                        </Col>
                    </Row>     
                </CardHeader>
                <CardBody>
                    <TableWithPaginationAndSort responsive 
                        rows={(queryResultData?.items || [])} 
                        columns={tableColumns} 
                        sortDirection="desc" 
                        sortByColumn="createdDate" 
                        showDownloadButtonXlsx="{true}"
                        showPageSizeDropDown="{true}"
                        filterGlobalEnabled="{true}"
                        id="order-reference-list"
                        on:row-click={navigateToItem}>
                        <div slot="custom-slot-4" let:row={row} let:column={column} on:click|stopPropagation>
                            <ButtonGroup>
                                <Button size="sm" color="danger" on:click={deleteGroup(row?.groupId)}>
                                    <Icon name="trash" />
                                </Button>
                            </ButtonGroup>
                        </div>                                    
                    </TableWithPaginationAndSort>
                </CardBody>
            </Card>
        {/if}

        <UploadModal bind:this={uploadModal} />
        <AskDeleteModal bind:this={askDeleteModal} />

        <Modal isOpen={refreshListIsRunning} centered>
            <ModalBody>
                <div class="d-flex justify-content-between">
                    <div>Lade Bestellreferenzgruppe {refreshListProgressCurrent} von {refreshListProgressMax}</div>
                </div>
                <Progress value={refreshListProgressCurrent } max={refreshListProgressMax} />
            </ModalBody>
        </Modal>

        <Modal isOpen={cacheListIsRunning} centered>
            <ModalBody>
                <div class="d-flex justify-content-between">
                    <div>Cachevorgang läuft {refreshListProgressCurrent} von {refreshListProgressMax}</div>
                </div>
                <Progress value={refreshListProgressCurrent } max={refreshListProgressMax} />
            </ModalBody>
        </Modal>

    </svelte:fragment>
</Base>

<script>

    import { onMount } from "svelte";
    import Base from "../Base.svelte";
    import UploadModal from "./_Upload.svelte";
    import AskDeleteModal from "./_AskDeleteModal.svelte";

    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle, 
        Icon ,
        Button, 
        ButtonGroup, 
        Row, 
        Col,
        Modal,
        Progress,
        ModalBody

    } from '@sveltestrap/sveltestrap'; 

    import { orderReferencesApi } from "@/clients/apdbClient";

    import { push } from "svelte-spa-router";

    let uploadModal;
    let askDeleteModal;

    let queryResultData = null;
    let queryResultStatus = null;

    const colorEinkauf = "#f9f9f9"
    const colorVerkaufAmazon = "#bbee99"
    const colorVerkaufJager = "#ffff99"
    const colorDisposal = "#fec1c1"

    let tableColumns = [
        { 
            displayName: 'Referenz Gruppe', 
            propertyName: 'groupName',
            style: `background-color: #fefefe;`
        },
        { 
            displayName: 'Lieferant', 
            propertyName: 'orderReferenceLieferant',
            width: '160px',
            style: `background-color: #fefefe;`
        },
        { 
            displayName: 'Zuordnung ab', 
            propertyName: 'orderReferenceStartDate',
            format: 'DATETIME',
            width: '160px',
            style: `background-color: #fefefe;`
        },

        { 
            displayName: 'Menge Einkauf Gesamt', 
            propertyName: 'einkaufTotalQuantity', 
            format: 'NUMBER_0_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorEinkauf};` 
        }, 
        { 
            displayName: 'Σ Kosten EK Gesamt', 
            propertyName: 'einkaufTotalPurchaseAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorEinkauf};` 
        },  

        { 
            displayName: 'Menge Verkauf Amazon Zustand Neu Gesamt', 
            propertyName: 'verkaufAmazonNewQuantity', 
            format: 'NUMBER_0_DECIMALS',  
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },   
        { 
            displayName: 'Menge Verkauf Amazon Zustand Gebraucht Gesamt', 
            propertyName: 'verkaufAmazonUsedQuantity', 
            format: 'NUMBER_0_DECIMALS',  
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },                       
        { 
            displayName: 'Umsatz (brutto) Amazon Gesamt', 
            propertyName: 'verkaufAmazonTotalSalesAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },               
        { 
            displayName: 'Ergebnis Amazon Gesamt', 
            propertyName: 'verkaufAmazonResultAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },

        { 
            displayName: 'Menge Verkauf Jager Zustand Neu Gesamt', 
            propertyName: 'verkaufJagerNewQuantity', 
            format: 'NUMBER_0_DECIMALS',  
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },   
        { 
            displayName: 'Menge Verkauf Jager Zustand Gebraucht Gesamt', 
            propertyName: 'verkaufJagerUsedQuantity', 
            format: 'NUMBER_0_DECIMALS',  
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },                       
        { 
            displayName: 'Umsatz (brutto) Jager Gesamt', 
            propertyName: 'verkaufJagerTotalSalesAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },               
        { 
            displayName: 'Ergebnis Jager Gesamt', 
            propertyName: 'verkaufJagerResultAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },
                   
        { 
            displayName: 'Menge Disposal Gesamt', 
            propertyName: 'disposalTotalQuantity', 
            format: 'NUMBER_0_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorDisposal};` 
        },               
        { 
            displayName: 'Kosten Disposal Gesamt', 
            propertyName: 'disposalTotalAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorDisposal};` 
        },     

        { 
            displayName: 'GuV Gesamt', 
            propertyName: 'guvTotalAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: #ffffff;` 
        },               
        { 
            displayName: 'GuV bereinigt Gesamt', 
            propertyName: 'guvTotalAmountAdjusted', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: #ffffff;` 
        },  

        { 
            displayName: '', 
            propertyName: '', 
            customSlot: '4', 
            excelIgnore: true,
            width: '40px',
            style: `padding: 0.25rem 0; text-align: right;`
        }
    ];

    let cacheListIsRunning = false;
    let refreshListIsRunning = false;
    let refreshListProgressMax = Number.POSITIVE_INFINITY;
    let refreshListProgressCurrent = 0;

    async function refreshList() {
        
        // Check Cache 
        let cacheState = await orderReferencesApi.getOrderReferenceCacheState().then(r => r.queryResultData);
        while (cacheState.numCached < cacheState.numTotal) {
            cacheListIsRunning = true;
            refreshListProgressCurrent = cacheState.numCached;
            refreshListProgressMax = cacheState.numTotal;
            await new Promise(resolve => setTimeout(resolve, 1000));
            cacheState = await orderReferencesApi.getOrderReferenceCacheState().then(r => r.queryResultData);
        }
        cacheListIsRunning = false;
        
        // Load Content
        refreshListProgressCurrent = 0;
        refreshListProgressMax = Number.POSITIVE_INFINITY;
        refreshListIsRunning = true;

        const orderReferenceSummary = await orderReferencesApi.getOrderReferenceGroups({});
        queryResultData = orderReferenceSummary?.queryResultData;
        queryResultStatus = orderReferenceSummary?.queryResultStatus;

        let groupIdsToFetch = queryResultData?.items?.map(item => item.groupId) ?? [];
        refreshListProgressMax = groupIdsToFetch.length;

        let chunkPromises = groupIdsToFetch.map(
            groupId => orderReferencesApi.getOrderReferenceGroupSummary({id: groupId})
                .then(chunkResult => {
                    return chunkResult?.queryResultData; 
                })
                .finally(() => refreshListProgressCurrent++)
        );
        let chunkResults = await Promise.allSettled(chunkPromises).then(values => values.map(v => v.value));
        queryResultData.items = chunkResults;

        refreshListIsRunning = false;

    }
    
    async function triggerNew() {
        await uploadModal.showDialog();
        await refreshList();
    }

    async function deleteGroup(groupId) {
        const shallDelete = await askDeleteModal.showDialog();
        if (shallDelete) {
            await orderReferencesApi.deleteOrderReferenceGroup({ groupId });
            await refreshList();
        }
    };

    function navigateToItem(onRowClickEvent) {
        const { groupId } = onRowClickEvent?.detail?.row;
        push(`/orderreferences/${groupId}`);
    }

    onMount(async () => {  
        return refreshList();
    });

</script>