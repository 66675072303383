<Container>
    <Row>
        <p>keine Parameter nötig</p>
    </Row>
</Container>

<script>

    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';
    
    export const inputDocument = {
    };

    export const inputIsValid = true;

</script>