import { writable, get } from "svelte/store";
import { isEqual } from "lodash";

const createWritableStore = (key, startValue) => {

    const json = localStorage.getItem(key);
    if (json) {
        startValue = JSON
            .parse(json)
            .filter(x => x.ean != null);
    }
    
    var store = writable(startValue)

    store.subscribe(current => {
        localStorage.setItem(key, JSON.stringify(current));
    });

    store.append = (item) => {
        let currentValue = get(store) || [];
        currentValue = currentValue.filter(x => !isEqual(x, item));
        let topN = [item, ...currentValue];
        topN = topN.slice(0, 50);
        store.set(topN);
    };

    return store;
}

export const productHistoryStore = createWritableStore('productHistory', []);

export default productHistoryStore;
