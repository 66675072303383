<Base title="Produktabfrage">
    <svelte:fragment slot="main">
        <Card class="mb-3">
            <CardHeader>
                <CardTitle>
                    Suche nach ASIN, EAN oder Titel
                    <p class="fw-light m-0">leider langsam</p>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <p>
                    Die Wildcardsuchvarianten (*) sind leider immer noch sehr langsam. Besser sind die Identsuchen "="!
                </p>
                <InputGroup class="mb-3">
                    <InputGroupText>
                        <Input addon type="select" bind:value={searchType}>
                            <option value={"EXACTLY_ASIN"}>= ASIN</option>
                            <option value={"EXACTLY_EAN"}>= EAN</option>
                            <option value={"EXACTLY_SKU_STORE"}>= SKU und Lager</option>
                            <option value={"LIKE_ASIN"}>= *ASIN*</option>
                            <option value={"LIKE_EAN"}>= *EAN*</option>
                            <option value={"LIKE_SKU"}>= *SKU*</option>
                            <option value={"FULLTEXT"}>= *EAN* oder *ASIN* oder *TITEL*</option>
                        </Input>
                    </InputGroupText>
                    <Input bind:value={searchTerm} on:keyup={onEnter} />
                    {#if searchType == 'EXACTLY_SKU_STORE'}
                    <Input addon type="select" bind:value={searchStore}>
                        <option value={"A_CHEABOO"}>A_CHEABOO</option>
                        <option value={"A_BUCHHANDEL"}>A_BUCHHANDEL</option>
                        <option value={"JAGER"}>JAGER</option>
                    </Input>
                    {/if}
                    <InputGroupText>
                        <Button addon on:click={onButton} disabled={!buttonEnabled}>Los!</Button>
                    </InputGroupText>
                </InputGroup>
            </CardBody>
        </Card>        
        {#if queryResultData}
            <Card>
                <CardBody>
                    <TableWithPaginationAndSort responsive 
                        rows={(queryResultData?.page || [])} 
                        columns={tableColumns} 
                        sortDirection="asc" 
                        sortByColumn="ean" 
                        on:row-click={navigateToItem} />                  
                </CardBody>
            </Card>    
        {:else}         
        <Card>
            <CardHeader>
                <CardTitle>
                    die zuletzt aufgerufenen Suchergebnisse
                </CardTitle>
            </CardHeader>            
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={($productHistoryStore || [])} 
                    columns={tableColumns} 
                    sortDirection="asc" 
                    sortByColumn="ean" 
                    on:row-click={navigateToItem} />                  
            </CardBody>
        </Card> 
        {/if}
    </svelte:fragment>
</Base>

<script>
    
    import { searchProduct } from "@/clients/apdbClient";

    import { push, pop, replace } from 'svelte-spa-router'
    import { productHistoryStore } from "@/stores/productHistoryStore";

    import Base from "../Base.svelte";
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import { 
        InputGroup, 
        Input, 
        InputGroupText,
        Icon,
        Button,
        Card,
        CardBody,
        CardHeader,
        CardTitle
    } from '@sveltestrap/sveltestrap';

    let searchType = 'EXACTLY_ASIN';
    let searchTerm = "";
    let searchStore = null;

    let buttonEnabled = false;
    $: {
        buttonEnabled = true;
        if ((searchType == 'EXACTLY_SKU_STORE') && ((searchStore || "").length <=0)) {
            buttonEnabled = false;
        } 
        if (((searchTerm || "").length <= 0)){
            buttonEnabled = false;
        } 
    }
    
    function onButton() {

        let query = { 
            searchType, 
            searchTerm, 
            store: (searchType == 'EXACTLY_SKU_STORE') ? searchStore : null
        };

        searchProduct(query).then(result => {
            queryResultData = result.queryResultData;
            queryResultStatus = result.queryResultStatus;
        });

    }

    function onEnter(e) {
        if (e.keyCode === 13) {
            if (buttonEnabled == true) {
                onButton();
            }
        }
    }

    function navigateToItem(onRowClickEvent) {
        const { ean, asin, title } = onRowClickEvent?.detail?.row;
        if (ean) {
            productHistoryStore.append({ ean, asin, title});
            push(`/products/${ean}`)
        }
    }

    let queryResultData = null;
    let queryResultStatus = null;

    let tableColumns = [
        { displayName: 'ASIN', propertyName: 'asin' },
        { displayName: 'EAN', propertyName: 'ean' },
        { displayName: 'Titel', propertyName: 'title' }
    ];

</script>