<div class="time-series-chart" bind:clientWidth={w} bind:clientHeight={h}>
    <canvas width="{w}" height="{h}" bind:this={chartCanvas} style="width: {w}px; height: {h}px;" />
</div>

<style lang="scss">
    .time-series-chart {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 240px;
    }
</style>

<script>

    import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

    import { onMount } from "svelte";
    import { debounce, merge, throttle } from 'lodash';
    import { DateTime, Duration } from 'luxon';

    import Chart from 'chart.js/auto';
    import 'chartjs-adapter-luxon';
    
    import zoomPlugin from 'chartjs-plugin-zoom';
    Chart.register(zoomPlugin);

    const filterToDate = DateTime.now().toISO({ includeOffset: false });
    const filterFromDate = DateTime.now().minus(Duration.fromObject({days: 180 })).toISO({ includeOffset: false });;
    export let labels = [
        DateTime.fromISO(filterFromDate), 
        DateTime.fromISO(filterToDate), 
    ];
    export let datasets = [];
    export let options = {};

    let w;
	let h;

    let chartCanvas;
    let chart;

    $: w, debouncedUpdateChart();
    $: h, debouncedUpdateChart();
    $: labels, debouncedUpdateChart();
    $: datasets, debouncedUpdateChart();
    
    function initChart() {

        if (!chartCanvas)
            return;

        if (chart) {
            chart.destroy();
        }

        // Merge Options
        let defaultOpions = {
            responsive: true, 
            maintainAspectRatio: false,
            animation: {
                duration: 0
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: "day"                                    
                    },
                    title: {
                        display: false
                    }                                
                },
                y: {
                    title: {
                        display: false
                    },
                    beginAtZero: true
                }
            },
            datasets: {
                scatter: {
                    stepped: false  
                }
            }, 
            elements: {
                point: {
                    radius: 3
                },
                line: {
                    fill: false                        
                }
            },
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                        onPanComplete: afterAreaOfInterestChanges
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                        onZoomComplete: afterAreaOfInterestChanges
                    }
                }
            }
        };
        let mergedOptions = merge({}, defaultOpions, options);

        // Display
        let ctx = chartCanvas.getContext('2d');
        chart = new Chart(ctx, {
            type: "scatter",
            data: {
                labels: [],
                datasets: []
            },
            options: mergedOptions
        });

    }

    function afterAreaOfInterestChanges() {

        // 10 Prozen extra
        let { min, max } = chart?.scales?.x?._range;
        if (min && max) {
            let delta10Percent = Math.abs(max - min);
            min -= delta10Percent;
            max += delta10Percent;
        }

        // aktuelle Auswahlgrenzen ermitteln
        let tMin = min ? DateTime.fromMillis(min)?.toISO({ includeOffset: false }) : null;
        let tMax = max ? DateTime.fromMillis(max)?.toISO({ includeOffset: false }) : null;
        
        // und als Ereignis nach außen geben        
        debouncedAreaOfInterestChanged({ 
            tMin, 
            tMax 
        });

    }

    const debouncedAreaOfInterestChanged = debounce(x => {
        dispatch('areaOfInterestChanged', x);
    }, 200);

    function updateChart() {

        if (!chart)
            return;

        chart.data.labels = labels;
        chart.data.datasets = datasets;
        chart.update();

    }

    const debouncedUpdateChart = debounce(updateChart, 500);

    onMount(async () => {        
        initChart();
        updateChart();
        setTimeout(() => {
            debouncedAreaOfInterestChanged({
                tMin: filterFromDate, 
                tMax: filterToDate
            });
        }, 500);
    });

</script>