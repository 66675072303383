<Base>
    <svelte:fragment slot="main">
    {#if queryResultData}
        <Card>
            <CardHeader>
                <Row>
                    <Col class="d-grid align-items-center">
                        <CardTitle class="mb-0">
                            Taskliste
                        </CardTitle>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup danger>
                            <Button color="primary" size="sm" on:click={() => uploadFile()}>
                                <Icon name="plus" />
                            </Button>                                
                            <Button color="secondary" size="sm" on:click={() => refreshList()}>
                                <Icon name="arrow-repeat" />
                            </Button>                             
                        </ButtonGroup>
                    </Col>
                </Row>     
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive rows={(queryResultData || [])} columns={tableColumns} sortDirection="desc" sortByColumn="submitted" id="tasklist" on:row-click={showDetails} filterGlobalEnabled="true">
                    <div slot="custom-slot-2" let:row={row} let:column={column}>
                        {@const p1 = row?.progressCount ?? 0}
                        {@const p2 = row?.progressMax ?? 100}
                        {@const px = ((p1/p2)*100.0).toFixed(1)}
                        {#if row[column.propertyName] == 'PENDING'}
                            <Icon name="pause-circle" class="text-secondary" />
                        {:else if row[column.propertyName] == 'INPROGRESS'}
                            <Progress animated value={p1} max={p2} class="mt-1 w-100 position-relative">
                                <div class="text-center w-100 position-absolute">{px}%</div>
                            </Progress>
                        {:else if row[column.propertyName] == 'FINISHED'}
                            <Icon name="check-circle" class="text-success" />
                        {:else if row[column.propertyName] == 'FAILED'}
                            <Icon name="x-circle" class="text-danger" id={`icon-${row.id}`}  />
                            <Tooltip target={`icon-${row.id}`}>{row.statusText}</Tooltip>
                        {/if}
                    </div>                                         
                </TableWithPaginationAndSort>
            </CardBody>
        </Card>

        <NewTaskModal bind:this={newTaskModal} />        
    {/if}
    </svelte:fragment>
</Base>

<script>

    import { onMount, onDestroy } from 'svelte';

    import { push } from 'svelte-spa-router'

    import { Card, CardBody, CardHeader, CardTitle, Icon } from '@sveltestrap/sveltestrap';
    import { Button, ButtonGroup } from '@sveltestrap/sveltestrap';
    import { Row, Col } from '@sveltestrap/sveltestrap';  
    import { Progress, Tooltip } from '@sveltestrap/sveltestrap';  

    import Base from "../Base.svelte";
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import { TASK_TYPES } from "./TaskTypes.js";
    import NewTaskModal from "./_NewTaskModal.svelte";
    let newTaskModal;

    import { getTaskList } from "@/clients/apdbClient";

    let refrehListInterval;
    onMount(async () => {  
        refrehListInterval = setInterval(refreshList, 5000);
        return refreshList();
    });

    onDestroy(async () => {
        clearInterval(refrehListInterval);
    });
    
    let queryResultData = null;
    let queryResultStatus = null;

    let tableColumns = [
        { 
            displayName: 'Status', 
            propertyName: 'status', 
            customSlot: '2', 
            style: 'width: 80px'
        },
        { 
            displayName: 'Typ', 
            propertyName: 'typ' 
        },
        { 
            displayName: 'Name', 
            propertyName: 'name' 
        },        
        { 
            displayName: 'angelegt', 
            propertyName: 'submitted', 
            format: 'DATETIME', 
            style: 'width: 180px'
        },
        { 
            displayName: 'gestartet', 
            propertyName: 'started', 
            format: 'DATETIME', 
            style: 'width: 180px' 
        },
        { 
            displayName: 'fertig', 
            propertyName: 'finished', 
            format: 'DATETIME', 
            style: 'width: 180px'
        }
    ];

    async function refreshList() {
        return getTaskList()
            .then(p => {
                queryResultData = p.queryResultData
                    .map(task => {
                        if (task.name == null)
                            task.name = TASK_TYPES.find(x => x.type == task.typ)?.text;
                        return task;
                    });
                queryResultStatus = p.queryResultStatus;
            });
    }

    async function uploadFile() {
        await newTaskModal.showDialog();
        await refreshList();
    };
    
    async function showDetails(onRowClickEvent) {
        const taskId = onRowClickEvent?.detail?.row?.id;
        push(`/tasks/${taskId}`);        
    }

</script>