<Container>
    <Row>
        <FormGroup>
            <a href="{`samples/FallbackValueImport.xlsx`}">
                Beispieldatei
            </a>
        </FormGroup>  
        <FormGroup>
            <Label for="mappenDatei">Datei</Label>
            <Input type="file" name="file" id="mappenDatei" 
                bind:files={selectedFiles} 
                valid={parsedFileIsValid === true} 
                invalid={parsedFileIsValid === false} 
                feedback={parsedFileValidationText} />                
        </FormGroup>        
    </Row>
</Container>

<script>

    import { 
        Col, 
        Container,
        Row 
    } from '@sveltestrap/sveltestrap';

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    import { 
        read,
        utils
    } from "xlsx";

    import { 
        readFileAsync 
    } from '@/utils/readFileAsync';

    let selectedFiles = null;
    let loadedFile = null;
    let loadedFileName = null;
    let parsedFileIsValid = null;
    let parsedFileValidationText = null;
    let parsedFileContent = null;

    $: selectedFiles, loadSelectedFiles();
    $: loadedFile, parseLoadedFile();

    async function loadSelectedFiles() {
        if (!selectedFiles || selectedFiles.length < 1) {
            loadedFile = null;
            loadedFileName = null;
        } else {
            try {
                const contentBuffer = await readFileAsync(selectedFiles[0]);
                const xlsxWorkbook = read(contentBuffer);
                const firstWorksheet = xlsxWorkbook.Sheets[xlsxWorkbook.SheetNames[0]];
                loadedFile = utils.sheet_to_json(firstWorksheet, {
                    blankrows: false,
                    raw: true
                });
                loadedFileName = selectedFiles[0].name;
            } catch (e) {
                alert(e)
            }
        }
    }

    async function parseLoadedFile() {
        
        const SPALTEN = ['EAN'];

        if (!loadedFile) {
            parsedFileIsValid = null;
            parsedFileValidationText = null;
            parsedFileContent = null;
        } else {
            try {
                // Prüfe auf Daten in Datei
                if (Array.isArray(loadedFile) == false) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }
                // Prüfe auf Spalten in Datei
                for (const [i, value] of SPALTEN.entries()) {
                    if (!(value in loadedFile[0])) {
                        parsedFileIsValid = false;
                        parsedFileValidationText = `Spalte ${value} nicht gefunden.`;
                        parsedFileContent = null;
                        return;
                    }
                }
                // Prüfe auf nicht leere Zeilen in EAN
                let nonNullRows = loadedFile
                    .filter(x => x['EAN']);
                if (nonNullRows.length <= 0) {
                    parsedFileIsValid = false;
                    parsedFileValidationText = `Keine Daten in Datei.`;
                    parsedFileContent = null;
                    return;
                }   

                // Mappe die Eingabedatei in die Ausgabestruktur für den BatchInput
                const productFallbackKeys = ['VLB_LP', 'VLB_LP_GEBUNDEN', 'VLB_MWST', 'VLB_AUTOR_NACHNAME', 'VLB_AUTOR_VORNAME']
                let batchInputRows = [];
                nonNullRows.forEach(nonNullRow => {
                    for (var fallbackKey of productFallbackKeys) {
                        if (nonNullRow[fallbackKey] !== undefined) {
                            let ean = String(nonNullRow['EAN']);
                            let itemCondition = nonNullRow['ITEM_CONDITION'] ?? null;
                            let fallbackValue, fallbackValueBool;
                            if (fallbackKey == 'VLB_LP_GEBUNDEN')
                                fallbackValueBool = (nonNullRow[fallbackKey]?.toLowerCase() == 'true');
                            else    
                                fallbackValue = String(nonNullRow[fallbackKey]);
                            batchInputRows.push({
                                ean,
                                itemCondition,
                                fallbackKey,
                                fallbackValue,
                                fallbackValueBool
                            });
                        }
                    }
                });

                // Es scheint so weit alles ok zu sein
                parsedFileIsValid = true;
                parsedFileValidationText = `${nonNullRows.length} Zeilen gefunden mit ${batchInputRows.length} Fallbacks`;
                parsedFileContent = {   
                    fileName: loadedFileName,             
                    items: batchInputRows
                };

            } catch (e) {
                alert(e);
            }
        }
    }

    export let inputDocument = {};
    $: {
        inputDocument = parsedFileContent;
    }

    export let inputIsValid = true;
    $: {
        inputIsValid = parsedFileIsValid;
    }

</script>