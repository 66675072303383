<div>
    <Modal isOpen={open}>
        <ModalHeader>Bestellreferenzdatei hochladen</ModalHeader>
        {#if uploadStep == 'PARSE'}
            <ModalBody>
                <p>
                    Hier kann eine Besetellreferenzdatei hochgeladen werden.
                </p>  
                <FormGroup>
                    <Label for="mappenDatei">
                        Datei
                        <small><a href="samples/BestellReferenzenUpload.xlsx" download>Beispieldatei</a></small>
                    </Label>
                    <Input type="file" id="mappenDatei" 
                        bind:files={selectedFiles} 
                        valid={parsedFileIsValid === true} 
                        invalid={parsedFileIsValid === false} 
                        feedback={parsedFileValidationText} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" 
                    disabled={canUpload == false} 
                    on:click={uploadFile}>
                    Datei hochladen
                </Button>
                <Button color="secondary" 
                    on:click={onCancel}>
                    Cancel
                </Button>
            </ModalFooter>
        {/if}
        {#if uploadStep == 'UPLOAD'}
            <ModalBody>
                <ListGroup style="max-height: 50vh; overflow: auto;">
                    {#each displayUploadResult as status}
                        <ListGroupItem bind:color={status.color}>
                            { status.referenceName }
                            {#if status.uploadMessage} 
                                {@const elemId = generateRandomId('upload-result-') }
                                <div class="monospace text-truncate" id="{elemId}">
                                    { status.uploadMessage }
                                </div>
                                <Tooltip target="{elemId}" placement="bottom">
                                    { status.uploadMessage }
                                </Tooltip>
                            {/if}
                        </ListGroupItem>
                    {/each}
                </ListGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" 
                    disabled={canClose == false} 
                    on:click={onClose}>
                    Schließen
                </Button>
            </ModalFooter>
        {/if}
    </Modal>
</div>

<script>

    import { 
        FormGroup, 
        Input, 
        Label,
        Button,
        Tooltip,
        Modal,
        ModalBody,
        ModalFooter,
        ModalHeader,
        ListGroup, 
        ListGroupItem 
    } from '@sveltestrap/sveltestrap';
    
    import { read, utils } from "xlsx";
    import { readFileAsync } from '@/utils/readFileAsync';
    import { generateRandomId } from '@/utils/generateRandomId';
    import { groupBy } from "lodash";
    import { DateTime } from "luxon";
    import { orderReferencesApi } from "@/clients/apdbClient";

    let selectedFiles = null;
    let loadedFile = null;

    let parsedFileIsValid = null;
    let parsedFileValidationText = null;
    let parsedFileContent = null;

    let open = false;
    let uploadStep = "PARSE";

    function getColorFromUploadStatus(status) {
        switch (status) {
            case 'PENDING': return 'light';
            case 'INPROGRESS': return 'primary';
            case 'OK': return 'success';
            case 'ERR': return 'danger';
            default: return 'warning'
        }
    }

    /**
    * Represents an array of upload results.
    * @type {Array<{ referenceName: string, uploadStatus: 'PENDING' | 'INPROGRESS' | 'OK' | 'ERR', uploadMessage: string }>}
    */
    let uploadResults = [];

    /**
     * uploadResults mit zusätzlicher gemappted color für die Anzeige
     */
    $: displayUploadResult = uploadResults?.map(
        r => ({ 
            ...r, 
            color: getColorFromUploadStatus(r.uploadStatus)
        })
    );

    $: selectedFiles, loadSelectedFiles();
    $: loadedFile, parseLoadedFile();

    $: canUpload = (parsedFileIsValid == true);
    let canClose = false;

    async function loadSelectedFiles() {
        if (!selectedFiles || selectedFiles.length < 1) {
            loadedFile = null;
        } else {
            try {
                const contentBuffer = await readFileAsync(selectedFiles[0]);
                const xlsxWorkbook = read(contentBuffer);
                const firstWorksheet = xlsxWorkbook.Sheets[xlsxWorkbook.SheetNames[0]];
                loadedFile = utils.sheet_to_json(firstWorksheet, {
                    blankrows: false,
                    raw: true
                });
            } catch (e) {
                alert(e)
            }
        }
    }

    async function parseLoadedFile() {
        if (!loadedFile) {
            parsedFileIsValid = null;
            parsedFileValidationText = null;
            parsedFileContent = null;
        } else {
            try {
                const validationResult = validateFileContent(loadedFile);
                console.log(validationResult);
                parsedFileIsValid = validationResult?.valid ?? false;
                parsedFileValidationText = validationResult?.message ?? "";
                parsedFileContent = validationResult?.content;
            } catch (e) {
                alert(e);
            }
        }
    }

    function validateFileContent(loadedFile) {

        const checkColumn = (columnName) => {
            if (!(columnName in loadedFile[0])) {
                return {
                    valid: false,
                    message: `Spalte "${columnName}" nicht gefunden.`,
                    content: null
                }
            }
        }

        const convertOaIsoDateString = (oaDate) => {
            // inspired by https://stackoverflow.com/a/15550284/1501375
            // and https://excel.tips.net/T002176_How_Excel_Stores_Dates_and_Times.html
            let days = parseInt(oaDate);
            var milliseconds = Math.abs((oaDate - days) * 8.64e7);
            let s = DateTime.fromFormat("1899-12-30", "yyyy-MM-dd");
            let x = s.plus({ days, milliseconds} );
            let y = x.toISO({ includeOffset: false });
            return y;
        };

        if (Array.isArray(loadedFile) == false) {
            return {
                valid: false,
                message: 'Keine Daten in Datei.',
                content: null
            }
        }

        // Prüfe auf Spalte Bestellreferenz
        let columnCheckBestellReferenzError = checkColumn("Bestellreferenz");
        if (columnCheckBestellReferenzError) 
            return columnCheckBestellReferenzError;

        // Prüfe auf Spalte Bestellreferenz
        let columnCheckBestellreferenzGruppeError = checkColumn("BestellreferenzGruppe");
        if (columnCheckBestellreferenzGruppeError) 
            return columnCheckBestellreferenzGruppeError;            

        // Prüfe auf Spalte Zuordnungsbeginn
        let columnCheckZuordnungsbeginnError = checkColumn("Zuordnungsbeginn");
        if (columnCheckZuordnungsbeginnError) 
            return columnCheckZuordnungsbeginnError;

        // Prüfe auf Spalte EAN
        let columnCheckEanError = checkColumn("EAN");
        if (columnCheckEanError) 
            return columnCheckEanError;

        // Prüfe auf Spalte EK
        let columnCheckEkError = checkColumn("EK");
        if (columnCheckEkError) 
            return columnCheckEkError;        

        // Prüfe auf Spalte Lager
        let columnCheckLagerError = checkColumn("Lager");
        if (columnCheckLagerError) 
            return columnCheckLagerError;

        // Prüfe auf Spalte Menge
        let columnCheckMengeError = checkColumn("Menge");
        if (columnCheckMengeError) 
            return columnCheckMengeError;

        // Prüfe auf Spalte Lieferant
        let columnCheckLieferantError = checkColumn("Lieferant");
        if (columnCheckLieferantError) 
            return columnCheckLieferantError;

        // Prüfe auf nicht leere Zeilen in EAN
        let nonNullRows = loadedFile.filter(x => x['EAN']);
        if (nonNullRows.length <= 0) {
            return {
                valid: false,
                message: 'Keine Daten in Datei.',
                content: null
            }
        }       

        // Prüfe auf nicht-13-stellige EANs in Datei
        let countNon13 = nonNullRows.filter(x => String(x['EAN'])?.length != 13).length;
        if (countNon13 > 0) {
            return {
                valid: false,
                message: `Datei enthällt ${countNon13} ungültige EANs.`,
                content: null
            }
        }

        // Mappe auf andere Struktur
        let mappedRows = nonNullRows
            .map(x => ({
                name: String(x['Bestellreferenz']),
                groupName: String(x['BestellreferenzGruppe']),
                startDate: convertOaIsoDateString(x['Zuordnungsbeginn']),
                lieferant: String(x['Lieferant']),
                ean: String(x['EAN']),
                ek: Number.parseFloat(x['EK']),
                store: x['Lager'],
                quantity: Number.parseInt(x['Menge']),
            }));

        // Nun Gruppiere die Bestellreferenzen nach `name`
        let returnGroups = groupBy(mappedRows, (r) => `${r.name}`);
        let orderReferences = Object.keys(returnGroups)
            .map(key => {
                // Extrahiere die Gruppen Items
                let items = returnGroups[key] ?? [];
                
                // Gruppiere die Items der Gruppe
                /** @type Map<String, { total_quantity: number, disposed_quantity: number, total_ek: number } */
                let groupedItems = items.reduce(
                    (   
                        /** @type {{ name: string, startDate: string, lieferant: string, ean: string, ek: number, store: string, quantity: number}} */ previous, 
                        /** @type Map<String, { total_quantity: number, disposed_quantity: number, total_ek: number } */ acc
                    ) => {
                        // Init new group if necessary
                        if (!previous.has(acc.ean)) {
                            previous.set(acc.ean, {
                                total_quantity: 0, 
                                disposed_quantity: 0, 
                                total_ek: 0
                            });
                        }
                        // accumulate
                        let g = previous.get(acc.ean);
                        g.total_quantity += acc.quantity;
                        if (acc.store == 'DISPOSAL')
                            g.disposed_quantity += acc.quantity;
                        g.total_ek += (acc.ek * acc.quantity);
                        // done
                        return previous;
                    }, 
                    new Map()
                );

                let mappedGroupedItems = [...groupedItems.keys()].map(key => ({
                    ean: key,
                    total_quantity: groupedItems.get(key).total_quantity,
                    disposed_quantity: groupedItems.get(key).disposed_quantity,
                    ek: (groupedItems.get(key).total_ek / groupedItems.get(key).total_quantity).toFixed(4),
                }));

                // @credits https://stackoverflow.com/a/71288846/1501375
                let startDate = items?.map(i => i.startDate)?.reduce((min, c) => c < min ? c : min);
                let name = items?.[0]?.name;
                let groupName = items?.[0]?.groupName;
                let lieferant = items?.[0]?.lieferant;

                return {
                    name, 
                    groupName,
                    startDate, 
                    lieferant,
                    items: mappedGroupedItems
                };
                
            });

        return {
            valid: true,
            message: `${orderReferences.length} Bestellreferenzen gefunden`,
            content: orderReferences
        };
    }

    async function uploadFile() {
        if (canUpload == true) {

            uploadStep = "UPLOAD";

            uploadResults = parsedFileContent?.map(orderReference => ({
                referenceName: orderReference.name,
                uploadStatus: 'PENDING',
                uploadMessage: ''
            })) ?? [];

            for (var orderReference of parsedFileContent) {
                let statusIndex = uploadResults.findIndex(x => x.referenceName == orderReference.name);
                let status = uploadResults[statusIndex]
                status.uploadStatus = 'INPROGRESS';
                await orderReferencesApi.createNewOrderReference(orderReference)
                    .then(() => {
                        status.uploadStatus = 'OK';
                    })
                    .catch(error => {
                        status.uploadStatus = 'ERR';
                        status.uploadMessage = `HTTP ${error?.response?.status} (${error.response?.data?.message})`;
                    });
                uploadResults[statusIndex] = status;
            }

            canClose = true;
        }
        return Promise.resolve();
    }

    let onClose = null;
    let onCancel = null;

    export async function showDialog() {
        selectedFiles = null;
        return new Promise((resolve, reject) => {
            open = true;
            uploadStep = "PARSE";
            onClose = async () => {
                open = false;
                resolve();
            }
            onCancel = () => {                
                open = false;
                reject();
            };
        });
    }

</script>

