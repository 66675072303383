<Base>
    <svelte:fragment slot="main">
    {#if queryResultData}
        <Card>
            <CardHeader>
                <CardTitle>
                    {tableCaption}
                </CardTitle>
                <CardSubtitle class="mt-1">
                    {tableSubCaption}
                </CardSubtitle>
                <CardSubtitle class="mt-1">
                    {(queryResultData?.finished ? formatIsoDateStringToLocalDateTime(queryResultData?.finished) : "-")}
                </CardSubtitle>
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={(queryResultData?.document?.items || [])} 
                    columns={tableColumns} 
                    sortDirection="desc" 
                    sortByColumn="submitted" 
                    showDownloadButtonXlsx="true"
                    showPageSizeDropDown="true"
                    filterGlobalEnabled="true"
                    on:row-click={navigateToItem} 
                    id="reports-detail">
                    <div slot="custom-slot-1" let:row={row} let:column={column}>
                        { row[column.propertyName] != null ? row[column.propertyName] : "-" }
                    </div>
                </TableWithPaginationAndSort>
            </CardBody>
        </Card>
    {/if}
    </svelte:fragment>
</Base>

<script>

    import { getReportDocument } from "@/clients/apdbClient";
    import { onMount } from 'svelte';
    import { formatIsoDateStringToLocalDateTime } from '@/utils/format';
    import { push } from 'svelte-spa-router'
    import Base from "../Base.svelte";
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    import { Card, CardBody, CardHeader, CardTitle, CardSubtitle } from '@sveltestrap/sveltestrap';

    export let params = {};

    onMount(async () => {
        getReportDocument(params.id)
            .then(p => {
                queryResultData = p.queryResultData;
                queryResultStatus = p.queryResultStatus;
            });
    });

    function navigateToItem(onRowClickEvent) {
        const { ean } = onRowClickEvent?.detail?.row;
        if (ean) {
            push(`/products/${ean}`);
        }
    }

    let queryResultData = null;
    let queryResultStatus = null;

    let tableColumns = [];
    let tableCaption = "";
    let tableSubCaption = "";

    $: {
        if (!(queryResultData?.type)) {
            tableColumns = [];
        } 
        else {
            switch (queryResultData.type) {
                case 'NEW_NO_BUYBOX': {
                    tableCaption = "NEU mit Buybox Abweichung";
                    tableColumns = [
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'Zeitpunkt', propertyName: 'timestamp', format: 'DATETIME' },
                        { displayName: 'Buybox Preis', propertyName: 'buyboxPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'Unser Preis', propertyName: 'ourPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'verfügbarer Bestand', propertyName: 'availableStock', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Expected BuyBox Price', propertyName: 'amazonExpecedBuyBoxPrice', format: 'NUMBER_2_DECIMALS' }
                    ];
                    break;
                }
                case 'FIXED_PRICES': {
                    tableCaption = "fixierte Preise";
                    tableColumns = [
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'instock', format: 'NUMBER_0_DECIMALS',  style: 'width: 120px;' },
                        { displayName: 'fixierter kkMinPrice', propertyName: 'kkMinPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fixierter kkPrice', propertyName: 'kkPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fixierter kkMaxPrice', propertyName: 'kkMaxPrice', format: 'NUMBER_2_DECIMALS' },
                    ];
                    break;
                }
                case 'PRICE_ERROR': {
                    tableCaption = "Preisfehler";
                    tableColumns = [
                        { displayName: 'EAN', propertyName: 'ean',  style: 'width: 120px;'},
                        { displayName: 'Zustand', propertyName: 'itemCondition',  style: 'width: 120px;' },
                        { displayName: 'Bestand', propertyName: 'instock', format: 'NUMBER_0_DECIMALS',  style: 'width: 120px;' },
                        { displayName: 'Preisfehler', propertyName: 'priceError' }
                    ];
                    break;
                }                
                case 'TARGET_ACTUAL_PRICE_DIFF': {
                    tableCaption = "SOLL/IST Preis Bericht";
                    tableSubCaption = "verfügbarer Bestand > 0; SOLL-Preis (Pricing) ungleich IST-Preis (Notification)"
                    tableColumns = [
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'verfügbarer Bestand', propertyName: 'availableStock', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Soll Preis', propertyName: 'latestTargetPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'Soll Datum', propertyName: 'latestTargetTimestamp', format: 'DATETIME' },
                        { displayName: 'Ist Preis', propertyName: 'latestActualPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'Ist Datum', propertyName: 'latestActualTimestamp', format: 'DATETIME' },
                        { displayName: 'SKU', propertyName: 'sku' }
                    ];
                    break;
                }
                case 'FBA_SELECTION': {
                    tableCaption = "Jager zu Amazon (FBA) Auswahl";
                    tableColumns = [
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Durchschnittsrang', propertyName: 'averageSalesrank', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Bestand Cheaboo', propertyName: 'bestandCheaboo', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Absatz30 Cheaboo', propertyName: 'absatz30Cheaboo', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Rw Cheaboo', propertyName: 'reichweite30Cheaboo', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Cheaboo Nettoauszahlung', propertyName: 'cheabooNettoAuszahlung', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Bestand FBA', propertyName: 'bestandAmazon', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(48, 100%, 96%)' },
                        { displayName: 'Absatz30 FBA', propertyName: 'absatz30Amazon', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(48, 100%, 96%)' },
                        { displayName: 'Rw ChFBAeaboo', propertyName: 'reichweite30Amazon', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(48, 100%, 96%)' },
                        { displayName: 'FBA Nettoauszahlung', propertyName: 'fbaNettoAuszahlung', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(48, 100%, 96%)' },
                        { displayName: 'Amazonkonto', propertyName: 'amazonKonto', customSlot: '1' },
                        { displayName: 'Klasse', propertyName: 'auswahlKlasse', customSlot: '1' },
                        { displayName: 'Menge', propertyName: 'auswahlMenge', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Sendungsnummer', propertyName: 'auswahlSendungsNummer', customSlot: '1' }
                    ];
                    break;                    
                }
                case 'FBM_SELECTION': {
                    tableCaption = "FBM Auswahl";
                    tableColumns = [
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Durchschnittsrang', propertyName: 'averageSalesrank', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Bestand Cheaboo', propertyName: 'bestandCheaboo', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Absatz30 Cheaboo', propertyName: 'absatz30Cheaboo', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Rw Cheaboo', propertyName: 'reichweiteCheaboo30', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Lageralter Cheaboo', propertyName: 'lagerAlterCheaboo', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(142, 52%, 96%)' },
                        { displayName: 'Bestand FBA', propertyName: 'fbaBestand', format: 'NUMBER_0_DECIMALS', style: 'background: hsl(48, 100%, 96%)' },
                        { displayName: 'Buybox Preis', propertyName: 'buyboxPreis', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(48, 100%, 96%)' },
                        { displayName: 'Min Preis Gebr', propertyName: 'minPreisGebraucht', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(48, 100%, 96%)' },
                        { displayName: 'FBM Nettoauszahlung', propertyName: 'fbmNettoAuszahlung', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(347, 90%, 96%)' },
                        { displayName: 'Cheaboo Nettoauszahlung', propertyName: 'cheabooNettoAuszahlung', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(347, 90%, 96%)' },
                        { displayName: 'Geb.LP.(DE)', propertyName: 'vlbFixedPrice' },
                        { displayName: 'VLB LP (DE)', propertyName: 'vlbFixedPriceAmount', format: 'NUMBER_2_DECIMALS' },                        
                        { displayName: 'add/delete', propertyName: 'feedAddDelete', style: 'background: hsl(206, 70%, 96%)' },
                        { displayName: 'price', propertyName: 'feedPrice', format: 'NUMBER_2_DECIMALS', style: 'background: hsl(206, 70%, 96%)' }
                    ];
                    break;                    
                }
                case 'PRICE_TREND': {
                    tableCaption = "Preistrend";
                    tableColumns = [
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Preis {€}', propertyName: 'latestLandedPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: '-72h {%}', propertyName: 'bin0delta', format: 'PERCENT' },
                        { displayName: '-66h {%}', propertyName: 'bin1delta', format: 'PERCENT' },
                        { displayName: '-60h {%}', propertyName: 'bin2delta', format: 'PERCENT' },
                        { displayName: '-54h {%}', propertyName: 'bin3delta', format: 'PERCENT' },
                        { displayName: '-48h {%}', propertyName: 'bin4delta', format: 'PERCENT' },
                        { displayName: '-42h {%}', propertyName: 'bin5delta', format: 'PERCENT' },
                        { displayName: '-36h {%}', propertyName: 'bin6delta', format: 'PERCENT' },
                        { displayName: '-30h {%}', propertyName: 'bin7delta', format: 'PERCENT' },
                        { displayName: '-24h {%}', propertyName: 'bin8delta', format: 'PERCENT' },
                        { displayName: '-18h {%}', propertyName: 'bin9delta', format: 'PERCENT' },
                        { displayName: '-12h {%}', propertyName: 'bin10delta', format: 'PERCENT' },
                        { displayName: '-6h {%}', propertyName: 'bin11delta', format: 'PERCENT' }
                    ];
                    // Daten aufbereiten
                    queryResultData?.document?.items?.forEach(item => {
                        for (let i=0; i<12; i++){
                            item[`bin${i}delta`]  = item.buckets?.find(bucket => bucket.n == i)?.deltaLandedPrice;
                            item[`bin${i}amount`] = item.buckets?.find(bucket => bucket.n == i)?.averageLandedPrice;
                        }
                    });
                    break;                    
                }
                case 'MISSING_VLB_PRICEDATA': {
                    tableCaption = "fehlende VLB Daten zu aktiven/inaktiven Angeboten";
                    tableColumns = [
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'Lager', propertyName: 'store' },
                        { displayName: 'ext. Artikel Nummer', propertyName: 'extItemId' },
                        { displayName: 'Bestand', propertyName: 'instock', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'VLB LP (DE)', propertyName: 'amountDe', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'FALLBACK LP', propertyName: 'fallbackAmount', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'VLB MWST (DE)', propertyName: 'taxDe', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'FALLBACK MWST', propertyName: 'fallbackTax', format: 'NUMBER_2_DECIMALS' }
                    ];
                    break;                    
                }
                case 'VALIDATE_FIXEDPRICE_01': {
                    tableCaption = "Vergleich zwischen LP (gebunden) und Preis";
                    tableSubCaption = "Filter: Zustand = NEU, geb. LP = Ja, \"LP - AKT-Preis\" ungleich 0,00 ODER ungleich \"-\", Bestand > 0"
                    tableColumns = [
                        { displayName: 'Lager', propertyName: 'store' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'ext. Artikel Nummer', propertyName: 'extItemId' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Geb.LP.(DE)', propertyName: 'gebLp' },
                        { displayName: 'VLB LP (DE)', propertyName: 'lp', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'VLB LP (DE) - Akt Preis', propertyName: 'lpMinusAktPreis', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Min Preis', propertyName: 'kkMinPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Preis', propertyName: 'kkPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Max Preis', propertyName: 'kkMaxPrice', format: 'NUMBER_2_DECIMALS' }
                    ];
                    break;                    
                }
                case 'VALIDATE_FIXEDPRICE_02': {
                    tableCaption = "zukünftige Änderungen an LP (gebunden)";
                    tableSubCaption = "Filter: Zustand = NEU, geb. LP = Ja, VLB LP (DE) NEU ungleich \"leer\""
                    tableColumns = [
                        { displayName: 'Lager', propertyName: 'store' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'ext. Artikel Nummer', propertyName: 'extItemId' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Geb.LP.(DE)', propertyName: 'gebLp' },
                        { displayName: 'VLB LP (DE)', propertyName: 'lp', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'VLB LP (DE) - gültig Bis', propertyName: 'lpGueltigBis' },
                        { displayName: 'VLB LP (DE) NEU', propertyName: 'lpNeu', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'VLB LP (DE) NEU - gültig Ab', propertyName: 'lpNeuGueltigAb' }
                    ];
                    break;                    
                }
                case 'VALIDATE_FIXEDPRICE_03': {
                    tableCaption = " LPs (gebunden mit Bestand) abweichend fixiert";
                    tableSubCaption = "Filter: Zustand = NEU, geb. LP = Ja, Bestand > 0, fixierter KKMINPreis ungleich LP, Lager = A_CHEABOO"
                    tableColumns = [
                        { displayName: 'Lager', propertyName: 'store' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'ext. Artikel Nummer', propertyName: 'extItemId' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Geb.LP.(DE)', propertyName: 'gebLp' },
                        { displayName: 'VLB LP (DE)', propertyName: 'lp', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Min Preis', propertyName: 'kkMinPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Preis', propertyName: 'kkPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Max Preis', propertyName: 'kkMaxPrice', format: 'NUMBER_2_DECIMALS' }
                    ];
                    break;                    
                }                
                case 'VALIDATE_FIXEDPRICE_04': {
                    tableCaption = "Vergleich zwischen LP (LP aufgehoben) und Fixierungen";
                    tableSubCaption = "Filter: Zustand = NEU, geb. LP = Nein, KK Min = LP"
                    tableColumns = [
                        { displayName: 'Lager', propertyName: 'store' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'ASIN', propertyName: 'asin' },
                        { displayName: 'ext. Artikel Nummer', propertyName: 'extItemId' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'Geb.LP.(DE)', propertyName: 'gebLp' },
                        { displayName: 'VLB LP (DE)', propertyName: 'lp', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Min Preis', propertyName: 'kkMinPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Preis', propertyName: 'kkPrice', format: 'NUMBER_2_DECIMALS' },
                        { displayName: 'fix. KK Max Preis', propertyName: 'kkMaxPrice', format: 'NUMBER_2_DECIMALS' }
                    ];
                    break;                    
                }
                case 'SKUS_WITHOUT_STOCK': {
                    tableCaption = "Alle SKUs aus Amazonkonten ohne Bestand";
                    tableSubCaption = "Filter: aktiv und Bestand = 0, unverk. Bestand = 0, Anlagedatum >= 7 Tage"
                    tableColumns = [
                        { displayName: 'Lager', propertyName: 'store' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'ext. Artikel Nummer', propertyName: 'extItemId' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'aktiv', propertyName: 'active', format: 'BOOLEAN' }
                    ];
                    break;                    
                }
                case 'LAGERBESTAND_OHNE_AKTIVES_ANGEBOT': {
                    tableCaption = "Lagerbestand ohne aktives Angebot in Amazonkonten";
                    tableSubCaption = "Filter: inaktiv und (Bestand > 0 ODER unverk. Bestand > 0)"
                    tableColumns = [
                        { displayName: 'Lager', propertyName: 'store' },
                        { displayName: 'EAN', propertyName: 'ean' },
                        { displayName: 'ext. Artikel Nummer', propertyName: 'extItemId' },
                        { displayName: 'Zustand', propertyName: 'itemCondition' },
                        { displayName: 'Bestand', propertyName: 'bestand', format: 'NUMBER_0_DECIMALS' },
                        { displayName: 'unverk. Bestand', propertyName: 'unsellableStock', format: 'NUMBER_0_DECIMALS' },
                    ];
                    break;                    
                }                
                default: {
                    let firstItem = queryResultData?.items?.[0];
                    if (firstItem != null) {
                        // TODO Build Columns from JSON properties
                        debugger;
                    }
                }
            }
        }
    }

</script>