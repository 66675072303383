<Card class="mb-3">
    <CardHeader>
        <p class="m-0">
            Amazon Preise { headerTitle }
        </p>
        <p class="fw-light m-0">
            <Icon name="calendar-date" id={'apt-tooltiptarget-date-' + newOrUsed}/> 
            { formatIsoDateStringToLocalDateTime(offerDate) }
            <Tooltip target={'apt-tooltiptarget-date-' + newOrUsed}>Datum des Datensatzes</Tooltip>
        </p>     
        <p class="fw-light m-0">
            <Icon name="broadcast-pin" id={'apt-tooltiptarget-source-' + newOrUsed}/> 
            { dataSource || 'N/A' }
            <Tooltip target={'apt-tooltiptarget-source-' + newOrUsed}>Quelle des Datensatzes</Tooltip>
        </p>
    </CardHeader>
    <CardBody>
        <TableWithPaginationAndSort responsive rows={offerTableRows} columns={tableColumns} sortDirection="asc" sortByColumn="totalPriceAmount"> 
            <div slot="custom-slot-3" let:row={row} let:column={column}>
                <span>
                    {convertSeller(row)}  
                    {#if row.sellerId}
                        <a href={"https://www.amazon.de/sp?seller=" + row.sellerId} target="_blank" title={row.sellerId}>
                            <Icon name="folder-symlink" />
                        </a>
                    {/if}     
                </span>
                {#if row.sellerPositiveFeedbackRating && row.sellerFeedbackCount}
                    <br>
                    <small style="white-space:nowrap;">
                        { formatNumberAsPercent(row.sellerPositiveFeedbackRating) } 
                        von 
                        { formatNumberAsZeroDecimalLocale(row.sellerFeedbackCount) }
                    </small>
                {/if}  
            </div>                   
        </TableWithPaginationAndSort>  
    </CardBody>
</Card> 

<script>

    import { orderBy } from 'lodash';

    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import {
        Card,
        CardBody,
        CardHeader,
        Icon,
        Tooltip
    } from '@sveltestrap/sveltestrap';
    
    import { 
        formatNumberAsZeroDecimalLocale, 
        formatNumberAsPercent,
        formatIsoDateStringToLocalDateTime
    } from '@/utils/format';

    export let newOrUsed;
    export let queryResultData;

    let offerTableRows = [];
    let offerDate = "";
    let dataSource = "";
    let headerTitle = "";

    $: {
        if (newOrUsed == 'new') {
            offerDate = queryResultData?.amazonLatestNewOffers?.createdAt;
            offerTableRows = orderBy(queryResultData?.amazonLatestNewOffers?.items || [], "totalPriceAmount"); 
            dataSource = queryResultData?.amazonLatestNewOffers?.dataSource;
            headerTitle = "NEU";
        }
        if (newOrUsed == 'used') {
            offerDate = queryResultData?.amazonLatestUsedOffers?.createdAt;
            offerTableRows = orderBy(queryResultData?.amazonLatestUsedOffers?.items || [], "totalPriceAmount");
            dataSource = queryResultData?.amazonLatestUsedOffers?.dataSource;
            headerTitle = "GEBRAUCHT";
        }
        if (newOrUsed == 'mws') {
            offerDate = queryResultData?.amazonLatestMwsOffers?.createdAt;
            offerTableRows = orderBy(queryResultData?.amazonLatestMwsOffers?.items || [], "totalPriceAmount");
            dataSource = queryResultData?.amazonLatestMwsOffers?.dataSource;
            headerTitle = "MWS";
        }
    }

    function convertSeller(row) {
        if (row.sellerName != null && row.sellerName.length > 0) 
            return row.sellerName;
        if (row.sellerId != null && row.sellerId.length > 0)
            return "Händler";
        return "???";
    }
        
    let tableColumns = [
        { displayName: 'SubCondition', propertyName: 'subCondition', style: 'width: 20%;' },
        { displayName: 'Seller', propertyName: 'sellerId', customSlot: '3' },
        { displayName: 'Total', propertyName: 'totalPriceAmount', format: 'NUMBER_2_DECIMALS', style: 'width: 15%;' },
        { displayName: 'BB', propertyName: 'isBuyBoxWinner', format: 'BOOLEAN', style: 'width: 15%;' }
    ]; 

</script>