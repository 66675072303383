{#if show}
    <div class="fullscreen-overlay d-flex justify-content-center align-items-center">
        <slot />
    </div>
{/if}

<style lang="scss">
    .fullscreen-overlay {
        position: fixed;        
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;        
        background-color: #ffffffcf;
        z-index: 9999;
    }
</style>

<script>
    export let show = true;
</script>