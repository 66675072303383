<Card class="mb-3 w-100">
    <CardHeader>
        Absatz Histogramm
    </CardHeader>
    <CardBody>
        <Table size="sm" responsive class="m-0" rows={rows} let:row>
            <Column header="">
                {salesChannelToText(row.salesChannel)}
            </Column>
            <Column header="30" width="2.5rem">
                {row.ctSales30}
            </Column>
            <Column header="90" width="2.5rem">
                {row.ctSales90}
            </Column>
            <Column header="180" width="2.5rem">
                {row.ctSales180}
            </Column>
            <Column header="365" width="2.5rem">
                {row.ctSales365}
            </Column>
        </Table>                         
    </CardBody>
</Card> 

<script>

    import {
        Card,
        CardBody,
        CardHeader,
        Table,
        Column
    } from '@sveltestrap/sveltestrap';
    import { salesChannelToText } from "@/utils/textenums";

    export let queryResultData;

    $: rows = [{
        salesChannel: "A_CHEABOO",
        ctSales30: queryResultData?.amazonSalesHistogram?.ctSales30 ?? 'n/a',
        ctSales90: queryResultData?.amazonSalesHistogram?.ctSales90 ?? 'n/a',
        ctSales180: queryResultData?.amazonSalesHistogram?.ctSales180 ?? 'n/a',
        ctSales365: queryResultData?.amazonSalesHistogram?.ctSales365 ?? 'n/a',
    }, {
        salesChannel: "A_BUCHHANDEL",
        ctSales30: queryResultData?.buchhandelSalesHistogram?.ctSales30 ?? 'n/a',
        ctSales90: queryResultData?.buchhandelSalesHistogram?.ctSales90 ?? 'n/a',
        ctSales180: queryResultData?.buchhandelSalesHistogram?.ctSales180 ?? 'n/a',
        ctSales365: queryResultData?.buchhandelSalesHistogram?.ctSales365 ?? 'n/a',
    }, {
        salesChannel: "A_123TRADE",
        ctSales30: queryResultData?.trade123SalesHistogram?.ctSales30 ?? 'n/a',
        ctSales90: queryResultData?.trade123SalesHistogram?.ctSales90 ?? 'n/a',
        ctSales180: queryResultData?.trade123SalesHistogram?.ctSales180 ?? 'n/a',
        ctSales365: queryResultData?.trade123SalesHistogram?.ctSales365 ?? 'n/a',
    }, {
        salesChannel: "JAGER",
        ctSales30: queryResultData?.cheabooSalesHistogram?.ctSales30 ?? 'n/a',
        ctSales90: queryResultData?.cheabooSalesHistogram?.ctSales90 ?? 'n/a',
        ctSales180: queryResultData?.cheabooSalesHistogram?.ctSales180 ?? 'n/a',
        ctSales365: queryResultData?.cheabooSalesHistogram?.ctSales365 ?? 'n/a',
    }];

</script>