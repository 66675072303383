<Card class="mb-3">
    <CardHeader>
        KK Preise { newOrUsed == 'new' ? 'NEU' : newOrUsed == 'used' ? "GEBRAUCHT" : "" }
    </CardHeader>
    <CardBody>
        <TimeSeriesChart
            options={chartOptions}
            labels={chartLabels}
            datasets={chartDatasets} 
            on:areaOfInterestChanged={areaOfInterestChanged}/>               
    </CardBody>
</Card> 

<script>

    import { formatNumberAsTwoDecimal } from '@/utils/format';
    import { DateTime, Duration } from 'luxon';
    import TimeSeriesChart from "@/components/TimeSeriesChart.svelte";
    import { orderBy } from "lodash";

    import {
        Card,
        CardBody,
        CardHeader,
    } from '@sveltestrap/sveltestrap';

    import { getProductChart } from "@/clients/apdbClient";

    const filterToDate = DateTime.now().toISO({ includeOffset: false });
    const filterFromDate = DateTime.now().minus(Duration.fromObject({days: 14})).toISO({ includeOffset: false });
    const chartLabels = [
        DateTime.fromISO(filterFromDate), 
        DateTime.fromISO(filterToDate), 
    ];

    const chartOptions = {
        plugins: {
            legend: {
                display: true
            },
            tooltip: {
                callbacks: {
                    footer: (tooltipItems) => {
                        const buyBoxIsOur = tooltipItems?.filter(x => x?.raw?.z === true)?.length > 0;
                        if (buyBoxIsOur == true)
                            return 'Buybox gewonnen';
                        const buyBoxIsNotOur = tooltipItems?.filter(x => x?.raw?.z === false)?.length > 0;
                        if (buyBoxIsNotOur == true)     
                            return 'Buybox nicht gewonnen';
                        return null;
                    },
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: 'dd.MM.yyyy HH:mm:ss',
                    unit: "day"                                    
                },
                title: {
                    display: true,
                    text: 'Datum'
                }                                
            }, 
            y: {
                min: 0,
                suggestedMax: 20,
                ticks: {
                    precision: 0.01
                }
            }
        }, 
        elements: {
            point: {
                radius: 3,
                backgroundColor: 'red',
                pointStyle: 'triangle'
            }
        }
    };

    let chartDataSetA = null;
    let chartDataSetB = null;

    async function areaOfInterestChanged(event) {
        chartDataSetA = await getProductChart(
            'price',
            queryResultData?.amazonMasterData?.asin, 
            newOrUsed?.toUpperCase(), 
            event.detail.tMin, 
            event.detail.tMax
        );
        chartDataSetB = await getProductChart(
            'notification',
            queryResultData?.amazonMasterData?.asin, 
            newOrUsed?.toUpperCase(), 
            event.detail.tMin, 
            event.detail.tMax
        );
    }

    export let newOrUsed;
    export let queryResultData;

    let chartDatasets = [];

    $: {

        let datasetA = orderBy((chartDataSetA?.queryResultData?.items || []), "binStart");
        let datasetB = orderBy((chartDataSetB?.queryResultData?.items || []), "binStart");

        chartDatasets = [{
                showLine: true,
                borderColor: 'orange',
                backgroundColor: 'orange',
                borderWidth: 1,
                label: 'KKPreis',
                data: datasetA
                        ?.map(dp => ({
                            x: DateTime.fromISO(dp.binStart), 
                            y: formatNumberAsTwoDecimal(dp.kkPrice)
                        }))
            },{
                showLine: true,
                borderColor: 'blue',
                backgroundColor: 'blue',
                borderWidth: 1,
                label: 'MinPreis',
                data: datasetA
                        ?.map(dp => ({
                            x: DateTime.fromISO(dp.binStart), 
                            y: formatNumberAsTwoDecimal(dp.minPrice)
                        }))
            },{
                showLine: true,
                borderColor: 'green',
                backgroundColor: 'green',
                borderWidth: 1,
                label: 'BbPreis',
                data: datasetA
                        ?.map(dp => ({
                            x: DateTime.fromISO(dp.binStart), 
                            y: formatNumberAsTwoDecimal(dp.bbPrice)
                        }))
            },{
                showLine: true,
                borderColor: 'red',
                backgroundColor: 'red',
                borderWidth: 1,
                label: 'OurLandedPrice',
                data: datasetB
                        ?.map(dp => ({
                            x: DateTime.fromISO(dp.binStart), 
                            y: formatNumberAsTwoDecimal(dp.ourLandedPrice),
                            z: dp.buyBoxIsOur
                        }))
            }
        ];

    }

</script>