<Base>
    <svelte:fragment slot="main">
    {#if inputDocument} 
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Eingabe
                </CardTitle>                
            </CardHeader>
            <CardBody>
                {JSON.stringify(inputDocument, "", 4)}
            </CardBody>
        </Card>    
    {/if}
    {#if outputDocument}
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Ausgabe
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={(outputDocument?.stats || [])} 
                    columns={resultColumns} 
                    sortDirection="desc" 
                    sortByColumn="action" 
                    showPageSizeDropDown="true" />
            </CardBody>
        </Card>  
    {/if}
    </svelte:fragment>
</Base>
<script>

    import Base from "../../Base.svelte";    
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle, 
        CardSubtitle 
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

    let resultColumns = [
        { 
            displayName: 'Aktion', 
            propertyName: 'action' 
        },
        { 
            displayName: 'Name', 
            propertyName: 'name' 
        },
        { 
            displayName: 'Menge', 
            propertyName: 'amount' 
        }
    ];

</script>