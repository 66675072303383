<Base>
    <svelte:fragment slot="main">
    {#if inputDocument} 
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Parameter
                </CardTitle>                
            </CardHeader>
            <CardBody>
                A/A
            </CardBody>
        </Card>    
    {/if}
    {#if outputDocument}
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Bestellreferenzen Detail Export
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={tableData} 
                    columns={tableColumns} 
                    sortDirection="desc" 
                    sortByColumn="submitted" 
                    showDownloadButtonCsv="true"
                    showPageSizeDropDown="true" />
            </CardBody>
        </Card>
    {/if}
    </svelte:fragment>
</Base>

<script>
 
    import Base from "../../Base.svelte";    
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    import { tableColumns } from "../../orderreferences/OrderReferenceDetails.svelte";

    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

    let tableData = [];
    $: {
        tableData = outputDocument
            ?.items
            ?.flatMap(item => {
                return item.items?.map(subItem => {
                    subItem.referenceName = item.name;
                    subItem.referenceSupplier = item.lieferant;
                    subItem.referenceStartDate = item.startDate; 
                    subItem.referenceCreatedDate = item.createdDate;                     
                    return subItem;
                })
            })
            ?? [];
    }


</script>