<FullScreenOverlay show={isLoading}>
    <Spinner color={'success'} />
</FullScreenOverlay>

<script>

    import { axiosClient } from "@/clients/apdbClient";

    import { Spinner } from '@sveltestrap/sveltestrap';
    import FullScreenOverlay from "@/components/FullScreenOverlay.svelte";

    // AXIOS Loading Indicator (can be disabled by setting disableLoadingIndicator to true)
    let isLoading = false;
    axiosClient.interceptors.request.use(
        (config) => {
            isLoading = !(config?.disableLoadingIndicator);
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axiosClient.interceptors.response.use(
        (response) => {
            isLoading = false;
            return response;
        }, 
        (error) => {
            isLoading = false;
            // https://stackoverflow.com/a/47681329/1501375
            return Promise.reject(error);
        }
    );

</script>